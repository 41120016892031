@use '../globals/media';

$this: '.content-picto';

#{$this} {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;

    h1 {
        margin-top: 0;
    }

    svg {
        width: 90px;
        height: 90px;
    }
}

@include media.screen(media.$medium, $this) {
    h1 {
        margin-top: 20px;
    }

    svg {
        width: 200px;
        height: 200px;
    }
}

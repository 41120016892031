@use '../globals/colors';
@use '../globals/media';

.content-youtube {
    margin-top: 1em;
    margin-bottom: 1em;

    iframe {
        width:100%;
        height:100%;
    }

    button {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        overflow: hidden;
        background: #fff;
        border: 2px solid colors.$secondary;
        border-radius: 10px;
        cursor: pointer;

        figcaption, span {
            display: block;
            padding: 5px 20px;
            color: #fff;
            background: colors.$secondary;
        }

        img {
            order: 2;
        }

        figcaption {
            order: 1;
        }

        span {
            order: 3;
        }
    }
}

@include media.screen(media.$medium, '.content-youtube') {
    button {
        height: 100%;

        img {
            position: absolute;
            inset: 50% auto auto 50%;
            transform: translate(-50%, -50%);
        }

        figcaption {
            position: absolute;
            inset: 0 0 auto;
        }

        span {
            position: absolute;
            inset: auto 0 0;
        }
    }
}

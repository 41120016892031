@import 'https://use.typekit.net/fja5zfc.css';
@font-face {
  font-weight: 200;
  font-family: Manrope;
  font-style: normal;
  src: local("Manrope"), local("Manrope-ExtraLight"), url("../fonts/Manrope-ExtraLight.woff2") format("woff2");
}
@font-face {
  font-weight: 300;
  font-family: Manrope;
  font-style: normal;
  src: local("Manrope"), local("Manrope-Light"), url("../fonts/Manrope-Light.woff2") format("woff2");
}
@font-face {
  font-weight: 400;
  font-family: Manrope;
  font-style: normal;
  src: local("Manrope"), local("Manrope-Regular"), url("../fonts/Manrope-Regular.woff2") format("woff2");
}
@font-face {
  font-weight: 500;
  font-family: Manrope;
  font-style: normal;
  src: local("Manrope"), local("Manrope-Medium"), url("../fonts/Manrope-Medium.woff2") format("woff2");
}
@font-face {
  font-weight: 600;
  font-family: Manrope;
  font-style: normal;
  src: local("Manrope"), local("Manrope-SemiBold"), url("../fonts/Manrope-SemiBold.woff2") format("woff2");
}
@font-face {
  font-weight: 700;
  font-family: Manrope;
  font-style: normal;
  src: local("Manrope"), local("Manrope-Bold"), url("../fonts/Manrope-Bold.woff2") format("woff2");
}
@font-face {
  font-weight: 800;
  font-family: Manrope;
  font-style: normal;
  src: local("Manrope"), local("Manrope-ExtraBold"), url("../fonts/Manrope-ExtraBold.woff2") format("woff2");
}
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  font-size: 16px;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  text-size-adjust: none;
  color: #000;
  font-weight: 300;
  font-size: 16px;
  font-family: Manrope, sans-serif;
  line-height: 1.5;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  font-size: 99%;
  font-family: cy, sans-serif;
  border-radius: 0;
}

textarea,
button,
input[type=email],
input[type=tel],
input[type=text],
input[type=submit],
input[type=search] {
  appearance: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  display: none;
}

textarea[disabled],
button[disabled],
input[type=email][disabled],
input[type=tel][disabled],
input[type=text][disabled],
input[type=submit][disabled],
input[type=search][disabled] {
  cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
strong,
b {
  font-weight: 700;
}

ul ul {
  margin-bottom: 0;
}

form,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

img,
iframe {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: none;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption,
turbo-frame {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  font-family: cy, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

caption,
th,
td {
  text-align: left;
  text-align: start;
}

tr > :first-child {
  padding-left: 0;
}

th {
  font-weight: 500;
}

table td,
table th {
  padding: 4px 15px;
  vertical-align: top;
}

abbr,
acronym {
  font-variant: normal;
  border-bottom: 1px dotted #000;
  cursor: help;
}

blockquote,
q {
  quotes: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: none;
}

pre,
code {
  font-family: monospace;
}

/* Vertical align */
.float_left {
  float: left;
}

.float_right {
  float: right;
}

/* Clear floats */
.clear,
#clear {
  clear: both;
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
}

/* Hide invisible elements */
.invisible,
.tns-visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
}

.handorgel .accordion {
  display: none;
  height: 0;
  overflow: hidden;
  transition: height 0.1s ease 0.1s;
}
.handorgel .accordion.open {
  display: block;
  transition: height 0.2s ease;
}
.handorgel .accordion.active {
  overflow: visible;
}

.nav-list ul,
.nav-list li,
.mod_navigation ul,
.mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li,
.mod_breadcrumb ul,
.mod_breadcrumb li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ce_download .size,
.ce_downloads .size {
  display: none;
}

fieldset {
  padding: 0;
  border: none;
}

input[type=radio],
input[type=checkbox] {
  position: absolute;
  visibility: hidden;
}
input[type=radio] + label,
input[type=checkbox] + label {
  position: relative;
  display: inline-block;
  margin: 5px 0;
  padding-left: 45px;
}
input[type=radio] + label::before,
input[type=checkbox] + label::before {
  position: absolute;
  top: 2px;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  border: 2px solid #e85a5e;
  border-radius: 50%;
  content: "";
}
input[type=radio]:checked + label::after,
input[type=checkbox]:checked + label::after {
  position: absolute;
  top: 6px;
  left: 4px;
  display: block;
  width: 17px;
  height: 17px;
  background: #e85a5e;
  border-radius: 50%;
  content: "";
}

.section-main h1,
.h1,
.headline-.h1 h1,
.headline-.h1 h2,
.headline-.h1 h3 {
  margin: 35px 0 25px;
  color: #642323;
  font-weight: 700;
  font-size: 25px;
  line-height: 1;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .section-main h1,
  .h1,
  .headline-.h1 h1,
  .headline-.h1 h2,
  .headline-.h1 h3 {
    margin: 50px 0;
  }
}

.section-main h2,
.h2,
.headline-.h2 h1,
.headline-.h2 h2,
.headline-.h2 h3 {
  margin: 2em 0 1em;
  color: #642323;
  font-weight: 700;
  font-size: inherit;
  line-height: 1.3;
}
@media screen and (min-width: 768px) {
  .section-main h2,
  .h2,
  .headline-.h2 h1,
  .headline-.h2 h2,
  .headline-.h2 h3 {
    font-size: 25px;
  }
}

.section-main h3,
.h3,
.headline-.h3 h1,
.headline-.h3 h2,
.headline-.h3 h3 {
  margin: 2em 0 1em;
  color: #642323;
  font-weight: 700;
  font-size: inherit;
  line-height: 1.3;
}

.headline-start h1, h1.headline-start,
.headline-start h2,
h2.headline-start,
.headline-start h3,
h3.headline-start {
  text-align: start;
}
@media screen and (min-width: 768px) {
  .headline-mdstart h1, h1.headline-mdstart,
  .headline-mdstart h2,
  h2.headline-mdstart,
  .headline-mdstart h3,
  h3.headline-mdstart {
    text-align: start;
  }
}
@media screen and (min-width: 1220px) {
  .headline-lgstart h1, h1.headline-lgstart,
  .headline-lgstart h2,
  h2.headline-lgstart,
  .headline-lgstart h3,
  h3.headline-lgstart {
    text-align: start;
  }
}
.headline-center h1, h1.headline-center,
.headline-center h2,
h2.headline-center,
.headline-center h3,
h3.headline-center {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .headline-mdcenter h1, h1.headline-mdcenter,
  .headline-mdcenter h2,
  h2.headline-mdcenter,
  .headline-mdcenter h3,
  h3.headline-mdcenter {
    text-align: center;
  }
}
@media screen and (min-width: 1220px) {
  .headline-lgcenter h1, h1.headline-lgcenter,
  .headline-lgcenter h2,
  h2.headline-lgcenter,
  .headline-lgcenter h3,
  h3.headline-lgcenter {
    text-align: center;
  }
}
.headline-end h1, h1.headline-end,
.headline-end h2,
h2.headline-end,
.headline-end h3,
h3.headline-end {
  text-align: end;
}
@media screen and (min-width: 768px) {
  .headline-mdend h1, h1.headline-mdend,
  .headline-mdend h2,
  h2.headline-mdend,
  .headline-mdend h3,
  h3.headline-mdend {
    text-align: end;
  }
}
@media screen and (min-width: 1220px) {
  .headline-lgend h1, h1.headline-lgend,
  .headline-lgend h2,
  h2.headline-lgend,
  .headline-lgend h3,
  h3.headline-lgend {
    text-align: end;
  }
}

body {
  position: relative;
  background: #f2f0ec;
}

.turbo-progress-bar {
  background-color: #e85a5e;
}

:root {
  --container: calc(100vh - 199px);
}
@media screen and (min-width: 768px) {
  :root {
    --container: calc(100vh - 246px);
  }
}

#wrapper {
  display: grid;
  grid-template: 1fr 109px/1fr;
  width: 100vw;
  min-height: 100vh;
  padding-top: 90px;
}
#wrapper .section-header {
  position: fixed;
  inset: 0 0 auto;
  height: 90px;
  z-index: 100;
}
@media screen and (min-width: 768px) {
  #wrapper {
    grid-template: 1fr 109px/1fr;
    padding-top: 137px;
  }
  #wrapper .section-header {
    height: 137px;
  }
}
@media screen and (max-height: 800px) {
  #wrapper {
    grid-template: 90px 1fr 109px/1fr;
    padding-top: 0;
  }
  #wrapper .section-header {
    position: static;
  }
}
@media screen and (min-width: 768px) and (max-height: 800px) {
  #wrapper {
    grid-template: 137px 1fr 109px/1fr;
  }
}

.section-main {
  font-size: 18px;
}
.section-main strong {
  font-weight: 900;
}
.section-main em {
  color: #e85a5e;
  font-style: normal;
}
.section-main .subheadline {
  margin-top: -1em;
  color: #e85a5e;
  font-size: 12px;
  line-height: 1.6;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .section-main .subheadline {
    margin: -40px 0 30px;
  }
}
.section-main .mod_article > [class*=content-],
.section-main .mod_article > [class*=module-] {
  margin-right: 30px;
  margin-left: 30px;
}

.layout--home #container {
  overflow: hidden;
}
.layout--home .section-main {
  position: relative;
}

.layout--content .section-main {
  max-width: 1200px;
  margin: 45px auto 0;
}
.layout--content .section-main .mod_article > .content-picto,
.layout--content .section-main .mod_article > .content-grid,
.layout--content .section-main .mod_article > .content-headline {
  max-width: none;
}
@media screen and (min-width: 768px) {
  .layout--content .section-main .mod_article > * {
    max-width: calc(100% - 460px);
  }
}
@media screen and (min-width: 768px) {
  .layout--content .section-main .content-grid {
    display: grid;
    grid-template-columns: 1fr 360px;
    gap: 50px;
    align-items: start;
  }
}
@media screen and (min-width: 1220px) {
  .layout--content .section-main .content-grid {
    gap: 40px;
  }
}

.illu--center .section-main,
.illu--contact .section-main {
  max-width: 750px;
  margin: 45px auto 0;
}
@media screen and (min-width: 768px) {
  .illu--center .section-main .mod_article > *,
  .illu--contact .section-main .mod_article > * {
    max-width: none !important;
  }
}

@media screen and (min-width: 768px) {
  .layout--content .section-main__inside {
    padding-top: 50px;
    padding-bottom: 75px;
  }
}

.section-header {
  background: #fff;
}
.section-header__inside {
  display: grid;
  grid-template: 1fr/1fr auto auto;
  align-items: center;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.section-header__logo {
  display: block;
  height: 30px;
  margin-left: 30px;
  background: url("../../images/logo.svg") 0 0/contain no-repeat;
}
.section-header__emergency {
  display: block;
  padding: 0 12px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  font-family: cy, sans-serif;
  line-height: 35px;
  background: #e85a5e;
  border-radius: 50px;
}

@media screen and (max-width: 767px) {
  .section-header__toggle {
    position: relative;
    width: 25px;
    height: 20px;
    margin: 0 29px 0 22px;
    background: none;
    border: none;
    cursor: pointer;
  }
  .section-header__toggle span {
    display: block;
    width: 25px;
    height: 2px;
    background: #e85a5e;
    transition: all 0.1s ease-in-out;
  }
  .section-header__toggle span::before, .section-header__toggle span::after {
    position: absolute;
    left: 0;
    width: 25px;
    height: 2px;
    background: #e85a5e;
    transition: all 0.1s ease-in-out;
    content: "";
  }
  .section-header__toggle span::before {
    top: 0;
  }
  .section-header__toggle span::after {
    bottom: 0;
  }
  body.has-overlay .section-header__toggle span {
    background: transparent;
  }
  body.has-overlay .section-header__toggle span::before {
    top: 9px;
    width: 25px;
    transform: rotateZ(45deg);
  }
  body.has-overlay .section-header__toggle span::after {
    bottom: 9px;
    width: 25px;
    transform: rotateZ(-45deg);
  }
  .section-header__overlay {
    position: fixed;
    inset: 80px 0 0;
    z-index: 999;
    display: grid;
    grid-template: 1fr 100px 100px/auto;
    padding: 27px;
    overflow-y: auto;
    background: #fff;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
  }
  body.has-overlay .section-header__overlay {
    opacity: 1;
    pointer-events: auto;
  }
  .section-header__overlay-illu {
    position: absolute;
    z-index: -1;
  }
  .section-header__overlay-illu--serpenti {
    inset: -4px auto auto -67px;
  }
  .section-header__overlay-illu--bubble {
    inset: 51vh calc(50vw - 160px) auto auto;
  }
  .section-header__overlay-illu--moon {
    inset: auto 26px -52px auto;
  }
  .section-header__overlay-illu--line {
    inset: auto 160px 80px auto;
    width: 405px;
  }
  .section-header__navigation {
    align-self: center;
    line-height: 1.1;
  }
  .section-header__navigation__group--level1 {
    max-width: 340px;
    margin: 0 auto;
  }
  .section-header__navigation__items--level1 {
    display: grid;
    grid-auto-flow: row;
    gap: 10px;
  }
  .section-header__navigation__items--level2 {
    display: grid;
    grid-auto-flow: row;
    gap: 9px;
    padding-top: 11px !important;
  }
  .section-header__navigation__link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 22px;
    font-family: cy, sans-serif;
    line-height: 1;
    border: 2px solid #e85a5e;
    border-radius: 50px;
    cursor: pointer;
  }
  .section-header__navigation__link--level1 {
    padding: 27px 0 26px;
    color: #642323;
    background: #fff;
  }
  .section-header__navigation__link--level2 {
    padding: 9px 0 10px;
    color: #e85a5e;
  }
  .section-header__navigation__back {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-indent: -999em;
    background: url("../../images/back.svg") 50% 50% no-repeat;
    border: none;
    cursor: pointer;
    display: block;
    margin-top: 25px;
    margin-left: calc(50% - 25px);
  }
  .section-header__social ul {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .section-header__social a {
    display: block;
    width: 35px;
    height: 35px;
    text-indent: -999em;
    background: #fff;
  }
  .section-header__social a.instagram {
    background: url("../../images/social__instagram.svg") 0 0/contain no-repeat;
  }
  .section-header__social a.youtube {
    background: url("../../images/social__youtube.svg") 0 0/contain no-repeat;
  }
  .section-header__social a.tiktok {
    background: url("../../images/social__tiktok.svg") 0 0/contain no-repeat;
  }
  .section-header__meta {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-header__meta ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .section-header__meta li.alerts--desktop {
    display: none;
  }
  .section-header__meta a {
    color: #d6d1c7;
    text-decoration: none;
  }
  .section-header__meta a.alerts {
    color: #e85a5e;
    text-decoration: underline;
  }
  .section-header__meta a:hover {
    text-decoration: underline;
  }
}
@media screen and (min-width: 768px) {
  .section-header__inside {
    grid-template: 1fr/1fr 3fr auto;
    margin-top: 15px;
  }
  .section-header__logo {
    order: 1;
    height: 40px;
  }
  .section-header__toggle {
    display: none;
  }
  .section-header__emergency {
    order: 3;
    font-size: 14px;
  }
  .section-header__overlay {
    order: 2;
  }
  .section-header__overlay-illu {
    display: none;
  }
  .section-header__navigation__back {
    display: none;
  }
  .section-header__navigation__items {
    display: grid;
    grid-auto-flow: column;
  }
  .section-header__navigation a,
  .section-header__navigation button {
    color: #642323;
    font-weight: 700;
    font-size: 14px;
    font-family: cy, sans-serif;
    background: none;
    border: none;
    cursor: pointer;
  }
  .section-header__navigation__group--level2 {
    display: none;
    position: absolute;
    min-width: 100%;
    margin-left: -28px;
    padding: 0;
    background: #fff;
  }
  .section-header__navigation__group--level2 > :first-child {
    padding: 35px 28px 18px;
  }
  .section-header__navigation__items--level2 {
    grid-auto-flow: row;
  }
  .section-header__navigation__items--level2 a {
    display: block;
    padding: 10px 28px 10px 0;
    color: #e85a5e;
    border-top: 1px solid #e85a5e;
    white-space: nowrap;
  }
  .section-header__navigation__item--level1.submenu {
    position: relative;
  }
  .section-header__meta {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 18px;
    font-family: cy, sans-serif;
  }
  .section-header__meta ul {
    display: flex;
    gap: 15px;
  }
  .section-header__meta li.alerts--mobile {
    display: none;
  }
  .section-header__meta a {
    color: #d6d1c7;
  }
  .section-header__meta a.alerts {
    color: #642323;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .section-header__social {
    display: none;
  }
}
.section-footer {
  color: #fff;
  font-size: 12px;
  background: #d6d1c7;
}
.section-footer__inside {
  display: grid;
  grid-template: 1fr auto/1fr;
  height: 100%;
}
.section-footer__social ul {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}
.section-footer__social a {
  display: block;
  width: 35px;
  height: 35px;
  text-indent: -999em;
  background: #fff;
}
.section-footer__social a.instagram {
  background: url("../../images/social__instagram--white.svg") 0 0/contain no-repeat;
}
.section-footer__social a.youtube {
  background: url("../../images/social__youtube--white.svg") 0 0/contain no-repeat;
}
.section-footer__social a.tiktok {
  background: url("../../images/social__tiktok--white.svg") 0 0/contain no-repeat;
}
.section-footer__copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 50px;
}
.section-footer__navigation ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}
.section-footer__navigation a {
  text-decoration: underline;
}

.content-accordion {
  position: relative;
  width: auto !important;
  margin: 130px 0 2em !important;
  border: none !important;
}
.content-grid .content-accordion {
  margin-left: -30px !important;
  margin-right: -30px !important;
}
.content-accordion .handorgel__header, .content-accordion .handorgel__content {
  background: #fff !important;
  border: none;
}
.content-accordion .handorgel__header {
  margin-top: 6px;
  margin-bottom: 0;
}
.content-accordion .handorgel__header__button {
  position: relative;
  padding: 15px 55px 15px 15px;
  font-size: 25px;
  line-height: 1;
  background: transparent !important;
}
.content-accordion .handorgel__header__button::after {
  position: absolute;
  width: 40px;
  height: 20px;
  background: url("../../images/accordion.svg") center center/contain no-repeat;
  transition: transform 0.2s ease-in-out;
  content: "";
  pointer-events: none;
  inset: auto 15px 15px auto;
}
.content-accordion .handorgel__header--open .handorgel__header__button::after {
  transform: rotateZ(180deg);
}
.content-accordion .handorgel__content__inner {
  padding: 0 15px;
  overflow: hidden;
}

.content-chatbot {
  margin: 0 -30px !important;
  padding: 20px 30px 35px;
  background: #fff;
}
.content-chatbot__links {
  display: grid;
  grid-gap: 2vw;
  grid-template: 1fr/repeat(3, 1fr);
}
.content-chatbot__link {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  font-family: cy, sans-serif;
  text-align: center;
  border: 2px solid transparent;
  border-radius: 50%;
}
.content-chatbot__link--alcohol {
  background-color: #a12700;
}
.content-chatbot__link:hover {
  color: #000;
  background: #fff;
  border-color: #a12700;
}
.content-chatbot__link--cannabis {
  background-color: #5b6423;
}
.content-chatbot__link:hover {
  color: #000;
  background: #fff;
  border-color: #5b6423;
}
.content-chatbot__link--tabac {
  background-color: #cf6745;
}
.content-chatbot__link:hover {
  color: #000;
  background: #fff;
  border-color: #cf6745;
}
.content-chatbot__link svg {
  margin-bottom: 2%;
  width: 50%;
  height: 50%;
}

@media screen and (min-width: 768px) {
  .content-chatbot {
    margin: 0 !important;
    padding: 0;
  }
}
.module-chatbot {
  margin-top: 2em;
  margin-bottom: 2em;
}
.module-chatbot__show {
  position: relative;
  z-index: 10;
  max-width: 330px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 15px 20px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  border: 2px solid #e85a5e;
  border-radius: 100px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  width: 100%;
  color: #fff;
  background: #e85a5e;
  box-shadow: none;
}
.module-chatbot__show:hover {
  color: #e85a5e;
  background: #fff;
}
.module-chatbot__popup {
  position: fixed;
  z-index: 2000;
  margin: 0 !important;
  padding: 0;
  transition: opacity 0.3s ease-in-out;
  inset: 0;
  opacity: 0;
  pointer-events: none;
}
.module-chatbot--popup .module-chatbot__popup {
  opacity: 1;
  pointer-events: auto;
}
.module-chatbot__inside {
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  padding: 72px 0 0;
  background: #fff;
}
.module-chatbot__logo {
  position: absolute;
  inset: 22px auto auto 30px;
  width: 150px;
  height: 30px;
  background: url("../../images/logo.svg") 0 0/contain no-repeat;
}
.module-chatbot__close {
  position: absolute;
  top: 25px;
  right: 25px;
  display: block;
  width: 25px;
  height: 25px;
  text-indent: -999em;
  background: transparent;
  border: none;
  cursor: pointer;
}
.module-chatbot__close::before, .module-chatbot__close::after {
  position: absolute;
  left: 0;
  width: 25px;
  height: 2px;
  background: #e85a5e;
  transition: all 0.1s ease-in-out;
  content: "";
}
.module-chatbot__close::before {
  top: 10px;
  transform: rotateZ(45deg);
}
.module-chatbot__close::after {
  top: 10px;
  transform: rotateZ(-45deg);
}
.module-chatbot__content {
  height: 100%;
}
.module-chatbot iframe {
  width: 100%;
  min-height: 100% !important;
}

@media screen and (min-width: 768px) {
  .module-chatbot__popup {
    display: grid;
    grid-template: 1fr/1fr;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(5px);
  }
  .module-chatbot__inside {
    height: calc(100vh - 60px);
    width: 960px;
    max-width: 80vh;
    padding: 72px 30px 42px;
    border-radius: 20px;
  }
}
.module-counseling__root, .module-counseling__filter {
  position: relative;
}
.module-counseling__navigation {
  position: relative;
  width: 100vw;
  min-height: var(--container);
  overflow: hidden;
}
.module-counseling__container {
  position: absolute;
  top: 0;
  left: 100vw;
  display: grid;
  width: 100%;
  min-height: var(--container);
  padding: 0 30px;
}
.module-counseling__container ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  max-width: 304px;
  margin: 0 auto;
  padding: 30px 0;
}
.module-counseling__container li {
  display: flex;
}
.module-counseling__container a,
.module-counseling__container button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 35px;
  color: #fff;
  font-weight: 700;
  font-size: 25px;
  font-family: cy, sans-serif;
  line-height: 1.25;
  text-align: center;
  background: #642323;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}
.module-counseling__container a:hover,
.module-counseling__container button:hover {
  background-color: #e85a5e;
}
.module-counseling__container a span + span,
.module-counseling__container button span + span {
  display: block;
  margin-top: 0.5em;
  font-size: 0.7em;
}
.module-counseling__container--level1 ul {
  max-width: 250px;
}
.module-counseling__container--level1 li {
  flex-grow: 1;
  max-height: 150px;
  width: 100%;
}
.module-counseling__container--level2, .module-counseling__container--level3 {
  align-items: center;
}
.module-counseling__container--level2 ul, .module-counseling__container--level3 ul {
  display: grid;
  max-width: 380px;
}
.module-counseling__container--level2 a,
.module-counseling__container--level2 button, .module-counseling__container--level3 a,
.module-counseling__container--level3 button {
  font-size: 16px;
}
.module-counseling__container--level2 a svg,
.module-counseling__container--level2 button svg, .module-counseling__container--level3 a svg,
.module-counseling__container--level3 button svg {
  width: 50%;
}
.module-counseling__container--level2 ul {
  grid-template: auto auto auto/1fr 1fr;
}
.module-counseling__container--level2 li {
  max-height: none;
  grid-row: 3/span 1;
  grid-column: 1/span 2;
  aspect-ratio: 1/0.25;
}
.module-counseling__container--level2 li.alcohol {
  grid-row: unset;
  grid-column: unset;
  aspect-ratio: 1/1;
}
.module-counseling__container--level2 li.cannabis {
  grid-row: unset;
  grid-column: unset;
  aspect-ratio: 1/1;
}
.module-counseling__container--level2 li.tabac {
  grid-row: unset;
  grid-column: unset;
  aspect-ratio: 1/1;
}
.module-counseling__container--level2 li.medicaments {
  grid-row: unset;
  grid-column: unset;
  aspect-ratio: 1/1;
}
.module-counseling__container--level2 a,
.module-counseling__container--level2 button {
  padding: 0;
}
.module-counseling__container--level2 a.alcohol,
.module-counseling__container--level2 button.alcohol {
  gap: 10%;
  justify-content: end;
  padding-bottom: 15%;
  background-color: #a12700;
  transition: background-color 0.1s ease-in-out;
}
.module-counseling__container--level2 a.alcohol svg,
.module-counseling__container--level2 button.alcohol svg {
  color: #e85a5e;
  transition: color 0.1s ease-in-out;
}
.module-counseling__container--level2 a.alcohol:hover,
.module-counseling__container--level2 button.alcohol:hover {
  background-color: #e85a5e;
}
.module-counseling__container--level2 a.alcohol:hover svg,
.module-counseling__container--level2 button.alcohol:hover svg {
  color: #642323;
}
.module-counseling__container--level2 a.cannabis,
.module-counseling__container--level2 button.cannabis {
  gap: 10%;
  justify-content: end;
  padding-bottom: 15%;
  background-color: #5b6423;
  transition: background-color 0.1s ease-in-out;
}
.module-counseling__container--level2 a.cannabis svg,
.module-counseling__container--level2 button.cannabis svg {
  color: #ffb78e;
  transition: color 0.1s ease-in-out;
}
.module-counseling__container--level2 a.cannabis:hover,
.module-counseling__container--level2 button.cannabis:hover {
  background-color: #e85a5e;
}
.module-counseling__container--level2 a.cannabis:hover svg,
.module-counseling__container--level2 button.cannabis:hover svg {
  color: #642323;
}
.module-counseling__container--level2 a.tabac,
.module-counseling__container--level2 button.tabac {
  gap: 10%;
  justify-content: end;
  padding-bottom: 15%;
  background-color: #cf6745;
  transition: background-color 0.1s ease-in-out;
}
.module-counseling__container--level2 a.tabac svg,
.module-counseling__container--level2 button.tabac svg {
  color: #ffb78e;
  transition: color 0.1s ease-in-out;
}
.module-counseling__container--level2 a.tabac:hover,
.module-counseling__container--level2 button.tabac:hover {
  background-color: #e85a5e;
}
.module-counseling__container--level2 a.tabac:hover svg,
.module-counseling__container--level2 button.tabac:hover svg {
  color: #642323;
}
.module-counseling__container--level2 a.medicaments,
.module-counseling__container--level2 button.medicaments {
  gap: 10%;
  justify-content: end;
  padding-bottom: 15%;
  background-color: #5e3e55;
  transition: background-color 0.1s ease-in-out;
}
.module-counseling__container--level2 a.medicaments svg,
.module-counseling__container--level2 button.medicaments svg {
  color: #e85a5e;
  transition: color 0.1s ease-in-out;
}
.module-counseling__container--level2 a.medicaments:hover,
.module-counseling__container--level2 button.medicaments:hover {
  background-color: #e85a5e;
}
.module-counseling__container--level2 a.medicaments:hover svg,
.module-counseling__container--level2 button.medicaments:hover svg {
  color: #642323;
}
.module-counseling__container--level3 ul {
  grid-auto-flow: row;
}
.module-counseling__container--level3 a,
.module-counseling__container--level3 button {
  padding: 20px;
}
.module-counseling__filter {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin: 0 auto;
  padding: 0 30px;
  min-height: var(--container);
}
.module-counseling__filter-illu {
  position: absolute;
  z-index: 10;
}
.module-counseling__filter-headline {
  position: relative;
  padding-left: 40px;
}
.module-counseling__filter-action {
  display: flex;
  justify-content: center;
  margin: 40px 0 140px;
}
.module-counseling__filter-action button {
  display: block;
  padding: 15px 20px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  border: 2px solid #e85a5e;
  border-radius: 100px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  width: 100%;
  color: #fff;
  background: #e85a5e;
  box-shadow: none;
}
.module-counseling__filter-action button:hover {
  color: #e85a5e;
  background: #fff;
}
.module-counseling__filter-action button {
  max-width: 330px;
}
.module-counseling__filter-back--mobile {
  display: inline-block;
  width: 25px;
  height: 25px;
  text-indent: -999em;
  background: url("../../images/back.svg") 50% 50% no-repeat;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 0;
}
.module-counseling__filter-back--desktop {
  display: none;
}
.module-counseling__filter fieldset {
  display: grid;
  grid-auto-flow: row;
}

@media screen and (min-width: 768px) {
  .module-counseling__root {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: var(--container);
  }
  .module-counseling__root > .content-popup {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
  }
  .module-counseling__navigation {
    flex-grow: 5;
    width: 1200px;
    max-width: 100vw;
    margin: 0 auto;
    min-height: 500px;
    height: 100%;
    z-index: 10;
  }
  .module-counseling__container {
    top: 100vh;
    left: auto;
    min-height: 0;
    height: 100%;
  }
  .module-counseling__container ul {
    gap: 30px;
  }
  .module-counseling__container--level1 ul {
    flex-direction: row;
    width: 100%;
    max-width: none;
  }
  .module-counseling__container--level1 li {
    width: 33.3333%;
    max-height: none;
    aspect-ratio: 1/1;
  }
  .module-counseling__container--level1 a,
  .module-counseling__container--level1 button {
    padding: 0 35px;
    font-size: 30px;
  }
  .module-counseling__container--level2 ul {
    grid-template: auto auto/repeat(4, 1fr);
    max-width: none;
  }
  .module-counseling__container--level2 li {
    grid-row: 2/span 1;
    grid-column: 1/span 4;
    height: 71px;
    aspect-ratio: inherit;
  }
  .module-counseling__container--level2 li.alcohol {
    grid-row: unset;
    grid-column: unset;
    height: unset;
    aspect-ratio: 1;
  }
  .module-counseling__container--level2 li.cannabis {
    grid-row: unset;
    grid-column: unset;
    height: unset;
    aspect-ratio: 1;
  }
  .module-counseling__container--level2 li.tabac {
    grid-row: unset;
    grid-column: unset;
    height: unset;
    aspect-ratio: 1;
  }
  .module-counseling__container--level2 li.medicaments {
    grid-row: unset;
    grid-column: unset;
    height: unset;
    aspect-ratio: 1;
  }
  .module-counseling__container--level3 ul {
    grid-template: repeat(2, 1fr)/repeat(3, 1fr);
    max-width: none;
  }
  .module-counseling__container--level3 li {
    aspect-ratio: 2/1;
  }
  .module-counseling__container--level3 a,
  .module-counseling__container--level3 button {
    font-size: 20px;
  }
  .module-counseling__filter {
    max-width: 1200px;
    justify-content: center;
  }
  .module-counseling__filter-headline,
  .module-counseling__filter .subheadline {
    text-align: left !important;
    padding-left: 0;
  }
  .module-counseling__filter-action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 90px;
  }
  .module-counseling__filter-back--mobile {
    display: none;
  }
  .module-counseling__filter-back--desktop {
    display: block;
    font-size: 12px;
    color: #e85a5e;
  }
}
.content-rsce-emergency-numbers {
  margin-bottom: 3em;
  color: #e85a5e;
}
.content-rsce-emergency-numbers__headline {
  margin-bottom: 2em !important;
}
.content-rsce-emergency-numbers__link {
  display: block !important;
  text-align: center;
}
.content-rsce-emergency-numbers__description {
  font-weight: 400;
}
.content-rsce-emergency-numbers__text {
  font-weight: 700;
  text-align: center;
}
.crisis-situation + .content-rsce-emergency-numbers .content-rsce-emergency-numbers__headline {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .content-rsce-emergency-numbers {
    margin-bottom: 0;
    padding: 30px;
    color: #fff;
    background: #e85a5e;
  }
  .content-rsce-emergency-numbers__headline {
    margin-top: 0 !important;
    color: #fff !important;
    font-size: 25px !important;
  }
  .content-rsce-emergency-numbers__link {
    border-color: #fff !important;
  }
}
.content-hyperlink {
  margin-top: 1em;
  margin-bottom: 2em;
}
.content-hyperlink a {
  display: inline-block;
  padding: 5px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  border: 2px solid #e85a5e;
  border-radius: 100px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  color: #e85a5e;
  background: #fff;
  box-shadow: none;
}
.content-hyperlink a:hover {
  color: #fff;
  background: #e85a5e;
}
.content-hyperlink.primary a {
  width: 100%;
  color: #fff;
  background: #e85a5e;
  box-shadow: none;
}
.content-hyperlink.primary a:hover {
  color: #e85a5e;
  background: #fff;
}
.content-hyperlink.block {
  max-width: 330px;
  margin-top: 2em;
  margin-right: auto !important;
  margin-left: auto !important;
}
.content-hyperlink.block a {
  display: block;
  padding: 15px 20px;
  text-align: center;
}
.content-hyperlink.back {
  position: absolute;
  margin: 0 20px !important;
}
.content-hyperlink.back a {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-indent: -999em;
  background: url("../../images/back.svg") 50% 50% no-repeat;
  border: none;
  cursor: pointer;
}
.content-hyperlink.back + .content-headline {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 50px;
}
.content-grid .content-hyperlink.back {
  display: none;
}
.content-hyperlink + .content-hyperlink {
  margin-top: -1em;
}

@media screen and (min-width: 768px) {
  .content-hyperlink.back {
    position: initial;
    margin: 2em 0 0 !important;
  }
  .content-rsce-emergency-numbers + .content-hyperlink.back, .content-hyperlink.block + .content-hyperlink.back {
    text-align: center;
  }
  .content-hyperlink.back a {
    width: auto;
    height: auto;
    font-weight: normal;
    padding-left: 40px;
    background-position: left center;
    text-indent: 0;
  }
  .content-hyperlink.back a:hover {
    color: initial;
  }
  .mod_article > .content-hyperlink.back {
    display: none;
  }
  .content-grid .content-hyperlink.back {
    display: block;
  }
}
.content-image {
  text-align: center;
}

.content-picto {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 35px;
}
.content-picto h1 {
  margin-top: 0;
}
.content-picto svg {
  width: 90px;
  height: 90px;
}

@media screen and (min-width: 768px) {
  .content-picto h1 {
    margin-top: 20px;
  }
  .content-picto svg {
    width: 200px;
    height: 200px;
  }
}
.content-popup__text a {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .content-popup {
    position: fixed;
    z-index: 2000;
    display: grid;
    grid-template: 1fr/1fr;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 30px;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.01);
    opacity: 1;
    backdrop-filter: blur(5px);
    transition: opacity 0.3s ease-in-out;
    inset: 0;
  }
  .content-popup--hidden {
    opacity: 0;
    pointer-events: none;
  }
  .content-popup__inside {
    position: relative;
    display: grid;
    grid-template: 1fr auto/1fr;
    max-height: calc(100vh - 60px);
    padding: 72px 30px 42px;
    text-align: center;
    background: #fff;
    border-radius: 20px;
  }
  .content-popup__close {
    position: absolute;
    top: 25px;
    right: 25px;
    display: block;
    width: 25px;
    height: 25px;
    text-indent: -999em;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .content-popup__close::before, .content-popup__close::after {
    position: absolute;
    left: 0;
    width: 25px;
    height: 2px;
    background: #e85a5e;
    transition: all 0.1s ease-in-out;
    content: "";
  }
  .content-popup__close::before {
    top: 10px;
    transform: rotateZ(45deg);
  }
  .content-popup__close::after {
    top: 10px;
    transform: rotateZ(-45deg);
  }
  .content-popup__headline {
    margin-top: 0 !important;
  }
  .content-popup__text {
    overflow-y: auto;
  }
  .content-popup__button {
    width: 100%;
    margin-top: 31px;
    padding: 16px 12px 15px;
    color: #fff;
    font-weight: 700;
    line-height: 1.25;
    background: #e85a5e;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  }
  .content-popup--intro .content-popup__headline {
    margin: 0 0 22px !important;
    font-weight: 700;
    font-size: 22px;
    color: #000;
  }
  .content-popup--intro .content-popup__logo {
    width: 220px;
    margin: 12px auto 0;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .content-popup__close, .content-popup__button {
    display: none;
  }
  .content-popup--intro {
    margin-top: 50px;
    text-align: center;
  }
  .content-popup--intro .content-popup__inside {
    max-width: 750px;
    margin: 0 auto;
  }
  .content-popup--intro .content-popup__headline {
    margin: 0 0 1em !important;
    font-weight: 700;
    font-size: 22px;
  }
  .content-popup--intro .content-popup__logo {
    display: inline;
    margin-left: 5px;
  }
  .content-popup--intro .content-popup__logo img {
    height: 1.5em;
  }
  .content-popup--intro .content-popup__text {
    font-size: 14px;
    line-height: 1.75;
  }
  .content-popup:not(.content-popup--intro) {
    padding: 30px;
    color: #fff;
    background: #e85a5e;
  }
  .content-popup:not(.content-popup--intro) h2 {
    margin-top: 0;
    color: #fff;
  }
  .content-popup:not(.content-popup--intro) .content-popup__link {
    border-color: #fff !important;
  }
}
.swirlytext {
  position: fixed;
  z-index: 1000;
  height: 0;
  overflow: hidden;
  color: #e85a5e;
  font-size: 72px;
  background: #642323;
  inset: auto 0 0;
}
.swirlytext__inner {
  position: absolute;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 20px;
  overflow: hidden;
  font-weight: 700;
  font-size: 72px;
  font-family: cy, sans-serif;
  line-height: 1;
  text-align: center;
  inset: auto 0 0;
}
.swirlytext--cannabis .swirlytext__inner {
  color: transparent;
  background-color: #e85a5e;
  background-image: url("../../images/cannabis-animation.webp");
  background-repeat: repeat;
  background-size: 50%;
  background-clip: text;
}
.swirlytext--tabac span {
  position: relative;
  display: inline-block;
  transform-origin: bottom;
}

@media screen and (min-width: 768px) {
  .swirlytext--cannabis .swirlytext__inner {
    background-size: 20%;
  }
}
.content-text h2,
.content-text p {
  transition: all 0.4s ease-in-out;
}
.content-text--blur h2,
.content-text--blur p {
  color: transparent;
  text-shadow: 0 0 32px #000;
}
.content-text p {
  text-align: justify;
  hyphens: auto;
}
.content-text a {
  text-decoration: underline;
}
:not(.handorgel__content__inner) > .content-text ol {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: numlist;
}
:not(.handorgel__content__inner) > .content-text ol li {
  position: relative;
  margin: 1em 0;
  padding-left: 48px;
  counter-increment: numlist;
}
:not(.handorgel__content__inner) > .content-text ol li::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  color: #e85a5e;
  font-size: 16px;
  font-family: cy, sans-serif;
  line-height: 30px;
  text-align: center;
  background: #642323;
  border-radius: 50%;
  content: counter(numlist);
}

@media screen and (min-width: 768px) {
  :not(.handorgel__content__inner) > .content-text ol li {
    margin: 1.5em 0;
    padding-left: 70px;
  }
}
.content-youtube {
  margin-top: 1em;
  margin-bottom: 1em;
}
.content-youtube iframe {
  width: 100%;
  height: 100%;
}
.content-youtube button {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff;
  border: 2px solid #e85a5e;
  border-radius: 10px;
  cursor: pointer;
}
.content-youtube button figcaption, .content-youtube button span {
  display: block;
  padding: 5px 20px;
  color: #fff;
  background: #e85a5e;
}
.content-youtube button img {
  order: 2;
}
.content-youtube button figcaption {
  order: 1;
}
.content-youtube button span {
  order: 3;
}

@media screen and (min-width: 768px) {
  .content-youtube button {
    height: 100%;
  }
  .content-youtube button img {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }
  .content-youtube button figcaption {
    position: absolute;
    inset: 0 0 auto;
  }
  .content-youtube button span {
    position: absolute;
    inset: auto 0 0;
  }
}
@keyframes illu-rotation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
.illu__container {
  position: absolute;
  width: 100%;
  inset: 0 auto;
  overflow: hidden;
  pointer-events: none;
}
.module-counseling__root .illu__container {
  top: -90px;
}
.illu__item {
  position: absolute;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
}
.illu__item img {
  width: 100%;
  height: auto;
  max-width: none;
}

@media screen and (max-width: 767px) {
  .illu--home .illu__item--serpenti {
    overflow: visible;
  }
  .illu--home .illu__item--explosion {
    overflow: visible;
  }
  .illu--home .illu__item--moon {
    overflow: visible;
  }
  .illu--home .illu__item--moon img {
    transform: rotateY(180deg);
  }
  .illu--home .illu__item--galaxy {
    overflow: visible;
  }
  .illu--home .illu__item--star8pink {
    position: fixed;
    top: 70px;
    right: 20px;
    width: 88px;
    animation: 10s linear infinite illu-rotation;
    z-index: 101;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  .illu--home .illu__item--circle {
    bottom: 43px;
    left: 10px;
    width: 96px;
  }
  .illu--home .illu__item--potato {
    right: -53px;
    bottom: 27px;
    width: 136px;
    z-index: 1;
  }
  .illu--home .illu__item--donut {
    display: none;
  }
  .illu--home .illu__item--wiggle {
    bottom: 22px;
    left: -36px;
    width: 267px;
  }
  .illu--home .illu__item--round {
    right: -70px;
    bottom: -147px;
    width: 200px;
  }
  .illu--home .illu__item--star6 {
    bottom: 75px;
    left: 14px;
    width: 89px;
    z-index: 1;
    opacity: 0;
  }
  .illu--home.has-overlay .illu__item--star8pink {
    opacity: 0;
  }
  .illu--substance .section-main {
    margin-bottom: 60px;
  }
  .illu--substance .illu__item--eye, .illu--substance .illu__item--round, .illu--substance .illu__item--star5 {
    display: none;
  }
  .illu--substance .illu__item--cloud {
    width: 240px;
    top: -60px;
    left: calc(50% - 120px);
  }
  .illu--substance .illu__item--cross {
    width: 171px;
    right: 0;
    bottom: -201px;
  }
  .illu--substance .illu__item--cross img {
    width: 201px;
  }
  .illu--substance .illu__item--line {
    width: 406px;
    right: 67px;
    bottom: 96px;
    z-index: 1;
  }
  .illu--aide .section-main {
    margin-bottom: 60px;
  }
  .illu--aide .illu__item--vu {
    display: none;
  }
  .illu--aide .illu__item--potato1 {
    top: 15%;
    right: -82px;
    width: 175px;
  }
  .illu--aide .illu__item--potato2 {
    top: calc(15% + 100px);
    right: -130px;
    width: 175px;
    transform: rotate(159.647deg);
  }
  .illu--aide .illu__item--explosion {
    right: -180px;
    top: 50%;
    width: 386px;
    height: 300px;
    transform: rotate(121.378deg);
  }
  .illu--aide .illu__item--questionmark {
    right: -39px;
    bottom: 75px;
    width: 135px;
    z-index: 1;
  }
  .illu--aide .illu__item--star4pink {
    right: 110px;
    bottom: 95px;
    width: 59px;
    z-index: 1;
  }
  .illu--urgence .section-main {
    margin-bottom: 120px;
  }
  .illu--urgence .illu__item--hand, .illu--urgence .illu__item--serpenti, .illu--urgence .illu__item--star8pink {
    display: none;
  }
  .illu--urgence .illu__item--moon {
    right: -7px;
    bottom: 66px;
    width: 157px;
    transform: rotate(90deg);
    z-index: 1;
  }
  .illu--urgence .content-rsce-emergency-numbers {
    position: relative;
  }
  .illu--urgence .content-rsce-emergency-numbers .illu__item--hand {
    display: block;
    bottom: -40px;
    left: -89px;
    width: 251.126px;
    transform: rotate(-34.076deg);
  }
  .illu--center .section-main, .illu--contact .section-main {
    margin-bottom: 185px;
  }
  .illu--center .illu__item--star4, .illu--center .illu__item--potato1, .illu--center .illu__item--potato2, .illu--contact .illu__item--star4, .illu--contact .illu__item--potato1, .illu--contact .illu__item--potato2 {
    display: none;
  }
  .illu--center .illu__item--galaxy, .illu--contact .illu__item--galaxy {
    right: -80px;
    bottom: 520px;
    width: 153px;
    transform: rotate(-76.792deg);
  }
  .illu--center .illu__item--donut, .illu--contact .illu__item--donut {
    bottom: 350px;
    left: -71px;
    width: 152px;
  }
  .illu--center .illu__item--blocsquare, .illu--contact .illu__item--blocsquare {
    width: 374px;
    right: -220px;
    bottom: -80px;
    z-index: 1;
  }
  .illu--center .illu__item--line, .illu--contact .illu__item--line {
    bottom: 199px;
    left: -96px;
    width: 292px;
  }
  .illu--center .illu__item--donut, .illu--center .illu__item--galaxy {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .illu__item {
    display: block !important;
  }
  .illu--home .illu__item--round {
    display: none;
  }
  .illu--home .illu__item--explosion {
    top: 132px;
    right: calc((100vw - 1200px) / 2 - 220px);
    width: 440px;
  }
  .illu--home .illu__item--serpenti {
    top: 270px;
    left: 25px;
    width: 217px;
  }
  .illu--home .illu__item--moon {
    bottom: 33px;
    left: calc((100vw - 1200px) / 2 + 294px);
    width: 258px;
  }
  .illu--home .illu__item--moon img {
    transform: rotateY(180deg);
  }
  .illu--home .illu__item--galaxy {
    top: 339px;
    left: calc((100vw - 1200px) / 2 + 217px);
    width: 241px;
  }
  .illu--home .illu__item--star8pink {
    bottom: 115px;
    left: calc((100vw - 1200px) / 2 + 13px);
    width: 132px;
    animation: 10s linear infinite illu-rotation;
  }
  .illu--home .illu__item--circle {
    top: 204px;
    left: -24px;
    width: 146px;
  }
  .illu--home .illu__item--potato {
    bottom: 133px;
    left: calc((100vw - 1200px) / 2 + 850px);
    width: 224px;
    z-index: 2;
  }
  .illu--home .illu__item--wiggle {
    left: calc((100vw - 1200px) / 2 + 800px);
    bottom: 300px;
    width: 510px;
  }
  .illu--home .illu__item--star6 {
    bottom: 175px;
    right: 32px;
    width: 106px;
    opacity: 0;
  }
  .illu--home .illu__item--donut {
    bottom: -58px;
    left: calc((100vw - 1200px) / 2 + 1000px);
    width: 247px;
    z-index: 1;
  }
  .illu--substance .section-main {
    margin-bottom: 100px;
  }
  .illu--substance .illu__item--cloud {
    width: 286px;
    top: 0;
    right: -220px;
  }
  .illu--substance .illu__item--star5 {
    top: -150px;
    right: -500px;
    width: 188px;
  }
  .illu--substance .illu__item--cross {
    right: -410px;
    bottom: -201px;
    width: 298px;
    z-index: 1;
  }
  .illu--substance .illu__item--cross img {
    width: 424px;
  }
  .illu--substance .illu__item--line {
    width: 738px;
    right: 67px;
    bottom: 70px;
    z-index: 1;
  }
  .illu--substance .illu__item--eye {
    left: -270px;
    bottom: -270px;
    width: 396px;
    transform: rotate(41.809deg);
  }
  .illu--substance .illu__item--round {
    top: 200px;
    right: calc(50vw - 600px);
    width: 219px;
  }
  .illu--aide .section-main {
    margin-bottom: 60px;
  }
  .illu--aide .illu__item--vu {
    top: 350px;
    left: calc((100vw - 1200px) / 2 - 120px);
    width: 426.2px;
    transform: rotate(35.346deg);
  }
  .illu--aide .illu__item--potato1 {
    top: 609px;
    right: -64px;
    width: 233px;
  }
  .illu--aide .illu__item--potato2 {
    top: 740px;
    right: -120px;
    width: 233.296px;
    transform: rotate(159.647deg);
  }
  .illu--aide .illu__item--explosion {
    top: 650px;
    left: calc((100vw - 1200px) / 2 - 320px);
    width: 657.475px;
    transform: rotate(125.454deg);
  }
  .illu--aide .illu__item--questionmark {
    right: -86px;
    bottom: 66px;
    width: 223px;
    z-index: 1;
  }
  .illu--aide .illu__item--star4pink {
    right: 191px;
    bottom: 158px;
    width: 103px;
  }
  .illu--urgence .illu__item--moon {
    top: 280px;
    left: calc((100vw - 1200px) / 2 + 720px);
    width: 284px;
    transform: rotateZ(188deg);
  }
  .illu--urgence .illu__item--serpenti {
    top: 50%;
    right: 40px;
    width: 175px;
    transform: rotate(-111.285deg);
  }
  .illu--urgence .illu__item--hand {
    bottom: 100px;
    right: -90px;
    width: 536.817px;
    transform: rotateY(180deg) rotateZ(-25deg);
    z-index: 1;
  }
  .illu--urgence .illu__item--star8pink {
    left: calc((100vw - 1200px) / 2 + 692px);
    bottom: 24px;
    width: 152px;
    z-index: 1;
  }
  .illu--urgence .content-rsce-emergency-numbers .illu__item--hand {
    display: none !important;
  }
  .illu--center .section-main, .illu--contact .section-main {
    max-width: 750px;
    margin: 45px auto 60px;
  }
  .illu--center .illu__item--galaxy, .illu--contact .illu__item--galaxy {
    display: none !important;
  }
  .illu--center .illu__item--star4, .illu--contact .illu__item--star4 {
    top: 306px;
    right: -49px;
    width: 158px;
  }
  .illu--center .illu__item--potato1, .illu--contact .illu__item--potato1 {
    top: 100px;
    left: 40px;
    width: 140px;
    transform: rotate(80deg);
  }
  .illu--center .illu__item--potato2, .illu--contact .illu__item--potato2 {
    top: 150px;
    left: -40px;
    width: 156.244px;
    transform: rotate(58.035deg);
  }
  .illu--center .illu__item--donut, .illu--contact .illu__item--donut {
    width: 435px;
    bottom: 18px;
    left: -300px;
  }
  .illu--center .illu__item--blocsquare, .illu--contact .illu__item--blocsquare {
    width: 504px;
    right: -200px;
    bottom: -82px;
    z-index: 1;
  }
  .illu--center .illu__item--line, .illu--contact .illu__item--line {
    width: 292px;
    left: calc((100vw - 1200px) / 2 + 135px);
    bottom: 182px;
  }
  .illu--center .section-main {
    max-width: 750px;
    margin: 45px auto 300px;
  }
}
@media screen and (min-width: 1200px) {
  .illu__page {
    max-width: 1200px;
    left: calc(50vw - 600px);
  }
  .illu--substance .illu__item--cross {
    right: auto;
    left: calc(50vw + 298px);
  }
}
@media screen and (min-width: 1480px) {
  .illu--center .illu__item--star4, .illu--contact .illu__item--star4 {
    right: -29px;
  }
  .illu--center .illu__item--donut, .illu--contact .illu__item--donut {
    left: -250px;
  }
  .illu--center .illu__item--blocsquare, .illu--contact .illu__item--blocsquare {
    right: -125px;
    bottom: -32px;
  }
}
:root {
  --sjs-font-questiondescription-weight: font-weight.$light;
  --sjs-font-editorfont-weight: font-weight.$light;
}

.sd-root-modern {
  overflow: visible !important;
  background-color: transparent !important;
}
.sd-root-modern .sv-components-container-center {
  position: relative;
}
.sd-root-modern .sd-progress-buttons--top {
  margin-top: -32px;
  margin-right: 40px;
}
.sd-root-modern .sd-btn {
  display: block;
  padding: 15px 20px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  border: 2px solid #e85a5e;
  border-radius: 100px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  color: #e85a5e !important;
  background: #fff !important;
  box-shadow: none;
}
.sd-root-modern .sd-btn:hover {
  color: #fff !important;
  background: #e85a5e !important;
}
.sd-root-modern .sd-navigation__start-btn,
.sd-root-modern .sd-navigation__next-btn {
  display: block;
  padding: 15px 20px;
  text-align: center;
  width: 100%;
  color: #fff !important;
  background: #e85a5e !important;
  box-shadow: none;
}
.sd-root-modern .sd-navigation__start-btn:hover,
.sd-root-modern .sd-navigation__next-btn:hover {
  color: #e85a5e !important;
  background: #fff !important;
}

.module-survey__request {
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: -100%;
  margin-left: 20px;
  padding: 20px;
  background: #fff;
  box-shadow: rgba(255, 255, 255, 0.5) 0px 7px 29px 0px;
  border-radius: 20px;
  transition: bottom 0.5s linear(0, 0.402 7.4%, 0.711 15.3%, 0.929 23.7%, 1.008 28.2%, 1.067 33%, 1.099 36.9%, 1.12 41%, 1.13 45.4%, 1.13 50.1%, 1.111 58.5%, 1.019 83.2%, 1.004 91.3%, 1);
}
.module-survey__request p {
  margin: 0 0 1em;
}
.module-survey--ready .module-survey__request {
  bottom: 20px;
}
.module-survey__action {
  display: inline-block;
  padding: 5px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  border: 2px solid #e85a5e;
  border-radius: 100px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  color: #e85a5e;
  background: #fff;
  box-shadow: none;
  margin-right: 10px;
}
.module-survey__action:hover {
  color: #fff;
  background: #e85a5e;
}
.module-survey__action--start {
  display: inline-block;
  padding: 5px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25;
  border: 2px solid #e85a5e;
  border-radius: 100px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  width: 100%;
  color: #fff;
  background: #e85a5e;
  box-shadow: none;
  width: auto;
}
.module-survey__action--start:hover {
  color: #e85a5e;
  background: #fff;
}
.module-survey__modal {
  position: fixed;
  inset: 0;
  overflow: auto;
  z-index: 2000;
  display: grid;
  grid-template: 1fr/1fr;
  align-items: center;
  justify-items: center;
  margin: 0 !important;
  padding: 30px;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(5px);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}
.module-survey--active .module-survey__modal {
  opacity: 1;
  pointer-events: auto;
}
.module-survey__inside {
  position: relative;
  display: grid;
  grid-template: 1fr auto/1fr;
  width: 460px;
  max-width: 80vw;
  padding: 42px 10px 10px;
  background: #fff;
  border-radius: 20px;
}
.module-survey__close {
  position: absolute;
  z-index: 10;
  top: 25px;
  right: 25px;
  display: block;
  width: 25px;
  height: 25px;
  text-indent: -999em;
  background: transparent;
  border: none;
  cursor: pointer;
}
.module-survey__close::before, .module-survey__close::after {
  position: absolute;
  left: 0;
  width: 25px;
  height: 2px;
  background: #e85a5e;
  transition: all 0.1s ease-in-out;
  content: "";
}
.module-survey__close::before {
  top: 10px;
  transform: rotateZ(45deg);
}
.module-survey__close::after {
  top: 10px;
  transform: rotateZ(-45deg);
}

@media screen and (min-width: 768px) {
  .module-survey__modal {
    padding: 30px;
  }
  .module-survey__inside {
    padding: 42px 30px;
    border-radius: 20px;
  }
}
@media screen and (max-width: 767px) {
  .content-text.crisis-situation {
    margin: 0 -30px;
    padding: 30px;
    color: #fff;
    background: #e85a5e;
  }
  .content-text.crisis-situation h2 {
    margin-top: 1em;
    color: inherit;
    text-align: center;
  }
  .content-text.crisis-situation ol li::before {
    background: #fff;
  }
}
@media screen and (min-width: 768px) {
  .content-text.crisis-situation {
    max-width: 650px;
  }
}

@media screen and (max-width: 767px) {
  .content-text.safety-position {
    margin: 0 !important;
    padding: 30px;
    background: #fff;
  }
  .content-text.safety-position h2 {
    max-width: 280px;
    margin: 0 0 2em 48px;
  }
  .content-text.safety-position p {
    margin: 2em 0;
    text-align: center;
  }
  .content-text.safety-position ol li::before {
    color: #fff;
    background: #e85a5e;
  }
}
@media screen and (min-width: 768px) {
  .content-text.safety-position p {
    margin-left: 70px;
  }
}

.contact {
  margin: 60px 0;
}
.contact p {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .contact {
    margin: 120px 0 0;
  }
}

@media screen and (min-width: 768px) {
  .content-element-group.aide {
    padding: 0 100px 30px;
    font-size: 16px;
    background: #fff;
  }
  .content-grid + .content-element-group.aide {
    margin-top: -4em;
  }
  .content-element-group.aide h2, .content-element-group.aide h3,
  .content-element-group.aide + .content-element-group h2,
  .content-element-group.aide + .content-element-group h3 {
    font-size: 18px;
    color: #000;
  }
  .content-element-group.aide h2,
  .content-element-group.aide + .content-element-group h2 {
    margin-top: 4em;
    margin-bottom: 2em;
  }
  .content-element-group.aide + .content-element-group h2:first-child, .content-element-group.aide + .content-element-group h3:first-child {
    margin-top: 0;
  }
  .content-grid > :first-child > h1:first-child,
  .content-grid > :first-child > h2:first-child,
  .content-grid > :first-child > h3:first-child {
    margin-top: 0;
  }
}
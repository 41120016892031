@use '../globals/colors';
@use '../globals/media';

body {
    position: relative;
    background: colors.$background;
}

.turbo-progress-bar {
    background-color: colors.$secondary;
}

:root {
    --container: calc(100vh - 199px);

    @include media.screen(media.$medium) {
        --container: calc(100vh - 246px);
    }
}

#wrapper {
    display: grid;
    grid-template: 1fr 109px / 1fr;
    width: 100vw;
    min-height: 100vh;
    padding-top: 90px;

    .section-header {
        position: fixed;
        inset: 0 0 auto;
        height: 90px;
        z-index: 100;
    }

    @include media.screen(media.$medium) {
        grid-template: 1fr 109px / 1fr;
        padding-top: 137px;

        .section-header {
            height: 137px;
        }
    }

    @include media.screen('max-height: 800px') {
        grid-template: 90px 1fr 109px / 1fr;
        padding-top: 0;

        .section-header {
            position: static;
        }
    }

    @include media.screen('#{media.$medium}) and (max-height: 800px') {
        grid-template: 137px 1fr 109px / 1fr;
    }
}

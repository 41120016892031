@use '../globals/colors';

.section-footer {
    color: #fff;
    font-size: 12px;
    background: colors.$footer;

    &__inside {
        display: grid;
        grid-template: 1fr auto / 1fr;
        height: 100%;
    }

    &__social {
        ul {
            display: flex;
            gap: 20px;
            align-items: flex-end;
            justify-content: center;
            height: 100%;
        }

        a {
            display: block;
            width: 35px;
            height: 35px;
            text-indent: -999em;
            background: #fff;

            &.instagram {
                background: url('../../images/social__instagram--white.svg') 0 0 / contain no-repeat;
            }

            &.youtube {
                background: url('../../images/social__youtube--white.svg') 0 0 / contain no-repeat;
            }

            &.tiktok {
                background: url('../../images/social__tiktok--white.svg') 0 0 / contain no-repeat;
            }
        }
    }

    &__copyright {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        height: 50px;
    }

    &__navigation {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 5px;
        }

        a {
            text-decoration: underline;
        }
    }
}

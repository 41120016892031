@use 'sass:string';

$mobile-only: 'max-width: 767px';
$medium: 'min-width: 768px';
$tablet-only: 'min-width: 768px) and (max-width: 1219px';
$large: 'min-width: 1220px';
$max: 'min-width: 1440px';

@mixin screen($size, $block: null) {
    @if type_of($size) == 'number' {
        $size: 'min-width: #{$size}px';
    }

    @media screen and (#{string.unquote($size)}) {
        @if $block {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

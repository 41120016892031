@use '../globals/colors';
@use '../globals/font-weight';
@use '../globals/media';

$this: '.swirlytext';

#{$this} {
    position: fixed;
    z-index: 1000;
    height: 0;
    overflow: hidden;
    color: colors.$secondary;
    font-size: 72px;
    background: colors.$primary;
    inset: auto 0 0;

    &__inner {
        position: absolute;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        height: 100vh;
        padding: 0 20px;
        overflow: hidden;
        font-weight: font-weight.$bold;
        font-size: 72px;
        font-family: $ci-font;
        line-height: 1;
        text-align: center;
        inset: auto 0 0;
    }

    &--cannabis {
        #{$this}__inner {
            color: transparent;
            background-color: colors.$secondary;
            background-image: url('../../images/cannabis-animation.webp');
            background-repeat: repeat;
            background-size: 50%;
            background-clip: text;
        }
    }

    &--tabac {
        span {
            position: relative;
            display: inline-block;
            transform-origin: bottom;
        }
    }
}

@include media.screen(media.$medium, $this) {
    &--cannabis {
        #{$this}__inner {
            background-size: 20%;
        }
    }
}

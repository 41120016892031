@use '../globals/colors';
@use '../globals/font-weight';
@use '../globals/media';

%h1,
.h1 {
    margin: 35px 0 25px;
    color: colors.$primary;
    font-weight: font-weight.$bold;
    font-size: 25px;
    line-height: 1;
    text-align: center;

    @include media.screen(media.$medium) {
        margin: 50px 0;
    }
}

%h2,
.h2 {
    margin: 2em 0 1em;
    color: colors.$primary;
    font-weight: font-weight.$bold;
    font-size: inherit;
    line-height: 1.3;

    @include media.screen(media.$medium) {
        font-size: 25px;
    }
}

%h3,
.h3 {
    margin: 2em 0 1em;
    color: colors.$primary;
    font-weight: font-weight.$bold;
    font-size: inherit;
    line-height: 1.3;
}

@each $level in [ '.h1', '.h2', '.h3'] {
    h1,
    h2,
    h3 {
        .headline-#{$level} & {
            @extend #{$level};
        }
    }
}

h1,
h2,
h3 {
    @each $position in [ 'start', 'center', 'end'] {
        .headline-#{$position} &,
        &.headline-#{$position} {
            text-align: #{$position};
        }

        @include media.screen(media.$medium) {
            .headline-md#{$position} &,
            &.headline-md#{$position} {
                text-align: #{$position};
            }
        }

        @include media.screen(media.$large) {
            .headline-lg#{$position} &,
            &.headline-lg#{$position} {
                text-align: #{$position};
            }
        }
    }
}

@import 'https://use.typekit.net/fja5zfc.css';

@font-face {
    font-weight: 200;
    font-family: Manrope;
    font-style: normal;
    src:
        local('Manrope'),
        local('Manrope-ExtraLight'),
        url('../fonts/Manrope-ExtraLight.woff2') format('woff2');
}

@font-face {
    font-weight: 300;
    font-family: Manrope;
    font-style: normal;
    src:
        local('Manrope'),
        local('Manrope-Light'),
        url('../fonts/Manrope-Light.woff2') format('woff2');
}

@font-face {
    font-weight: 400;
    font-family: Manrope;
    font-style: normal;
    src:
        local('Manrope'),
        local('Manrope-Regular'),
        url('../fonts/Manrope-Regular.woff2') format('woff2');
}

@font-face {
    font-weight: 500;
    font-family: Manrope;
    font-style: normal;
    src:
        local('Manrope'),
        local('Manrope-Medium'),
        url('../fonts/Manrope-Medium.woff2') format('woff2');
}

@font-face {
    font-weight: 600;
    font-family: Manrope;
    font-style: normal;
    src:
        local('Manrope'),
        local('Manrope-SemiBold'),
        url('../fonts/Manrope-SemiBold.woff2') format('woff2');
}

@font-face {
    font-weight: 700;
    font-family: Manrope;
    font-style: normal;
    src:
        local('Manrope'),
        local('Manrope-Bold'),
        url('../fonts/Manrope-Bold.woff2') format('woff2');
}

@font-face {
    font-weight: 800;
    font-family: Manrope;
    font-style: normal;
    src:
        local('Manrope'),
        local('Manrope-ExtraBold'),
        url('../fonts/Manrope-ExtraBold.woff2') format('woff2');
}

@use '../globals/style';
@use '../globals/media';
@use '../base/elements';

.content-hyperlink {
    margin-top: 1em;
    margin-bottom: 2em;

    a {
        @include style.linkbox;
    }

    &.primary a {
        @include style.linkbox(true, false, true);
    }

    &.block {
        max-width: 330px;
        margin-top: 2em;
        margin-right: auto !important;
        margin-left: auto !important;

        a {
            @include style.linkbox(false, true, true);
        }
    }

    &.back {
        position: absolute;
        margin: 0 20px !important;

        a {
            @include elements.link-back();
        }

        + .content-headline {
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 50px;
        }

        .content-grid & {
            display: none;
        }
    }

    + .content-hyperlink {
        margin-top: -1em;
    }
}

@include media.screen(media.$medium, '.content-hyperlink') {
    &.back {
        position: initial;
        margin: 2em 0 0 !important;

        .content-rsce-emergency-numbers + &,
        .content-hyperlink.block + & {
            text-align: center;
        }

        a {
            width: auto;
            height: auto;
            font-weight: normal;
            //background: none;
            padding-left: 40px;
            background-position: left center;
            text-indent: 0;

            &:hover {
                color: initial;
            }
        }

        .mod_article > & {
            display: none;
        }

        .content-grid & {
            display: block;
        }
    }
}

@use 'sass:map';
@use 'sass:string';

$body-font: string.unquote('Manrope, sans-serif');
$ci-font: string.unquote('cy, sans-serif');
$headline-font: $ci-font;
$input-font: $ci-font;
$screen-large: 1200px;

@use './globals/colors';

$color: (
    text: colors.$text,
    primary: colors.$primary,
    secondary: colors.$secondary,
    accent: colors.$accent,
    background: colors.$background,

    alcohol: colors.$alcohol,
    alcohol-text: colors.$alcohol-text,
    cannabis: colors.$cannabis,
    cannabis-text: colors.$cannabis-text,
    tabac: colors.$tabac,
    tabac-text: colors.$tabac-text,
    medicaments: colors.$medicaments,
    medicaments-text: colors.$medicaments-text,

    header: colors.$header,
    footer: colors.$footer,
);

@mixin screen($size, $block: null) {
    $sizes: (
        mobile-only: 'max-width: 767px',
        medium: 'min-width: 768px',
        // tablet-only: "min-width: 768px) and (max-width: 1219px",
        large: 'min-width: 1220px',
        max: 'min-width: 1440px',
    );

    @if map.has_key($sizes, $size) {
        $size: map.get($sizes, $size);
    } @else if type_of($size) == 'number' {
        $size: 'min-width: #{$size}px';
    }

    @media screen and (#{string.unquote($size)}) {
        @if $block {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

%errorbox {
    display: block;
    padding: 10px 20px;
    background: red;
}

%successbox {
    display: block;
    padding: 10px 20px;
    color: #fff;
    background: green;
}

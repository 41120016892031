@use './globals/colors';

fieldset {
    padding: 0;
    border: none;
}

input[type='radio'],
input[type='checkbox'] {
    position: absolute;
    visibility: hidden;

    + label {
        position: relative;
        display: inline-block;
        margin: 5px 0;
        padding-left: 45px;

        &::before {
            position: absolute;
            top: 2px;
            left: 0;
            display: block;
            width: 25px;
            height: 25px;
            border: 2px solid colors.$secondary;
            border-radius: 50%;
            content: '';
        }
    }

    &:checked + label {
        &::after {
            position: absolute;
            top: 6px;
            left: 4px;
            display: block;
            width: 17px;
            height: 17px;
            background: colors.$secondary;
            border-radius: 50%;
            content: '';
        }
    }
}

@use './font-weight';
@use './colors';

@mixin linkbox($primary: false, $block: false, $only: false, $important: false) {
    @if ($important) {
        $important: !important;
    } @else {
        $important: null;
    }

    @if $block {
        display: block;
        padding: 15px 20px;
        text-align: center;
    } @else if not $only {
        display: inline-block;
        padding: 5px 20px $important;
    }

    @if not $only {
        font-weight: font-weight.$bold;
        font-size: 16px;
        line-height: 1.25;
        border: 2px solid colors.$secondary;
        border-radius: 100px;
        transition: all 0.15s ease-in-out;
        cursor: pointer;
    }

    @if $primary {
        width: 100%;
        color: #fff $important;
        background: colors.$secondary $important;
        box-shadow: none;

        &:hover {
            color: colors.$secondary $important;
            background: #fff $important;
        }
    } @else if not $only {
        color: colors.$secondary $important;
        background: #fff $important;
        box-shadow: none;

        &:hover {
            color: #fff $important;
            background: colors.$secondary $important;
        }
    }
}

@use './globals/colors';
@use './globals/font-weight';

$body-font: 'Arial, sans-serif' !default;
$body-line-height: 1.5;
$headline-font: 'Arial, sans-serif' !default;
$input-font: 'Arial, sans-serif' !default;
$font-weight: (
    thin: font-weight.$thin,
    xlight: font-weight.$xlight,
    light: font-weight.$light,
    regular: font-weight.$regular,
    medium: font-weight.$medium,
    semibold: font-weight.$semibold,
    bold: font-weight.$bold,
    xbold: font-weight.$xbold,
    black: font-weight.$black,
) !default;

html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    overscroll-behavior-y: contain;
    font-size: 16px;
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    text-size-adjust: none;
    color: colors.$text;
    font-weight: font-weight.$light;
    font-size: 16px;
    font-family: $body-font;
    line-height: $body-line-height;
}

input,
button,
textarea,
select {
    margin: 0;
    padding: 0;
    color: inherit;
    font-weight: inherit;
    font-size: 99%;
    font-family: $input-font;
    border-radius: 0;
}

textarea,
button,
input[type='email'],
input[type='tel'],
input[type='text'],
input[type='submit'],
input[type='search'] {
    appearance: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
    display: none;
}

textarea[disabled],
button[disabled],
input[type='email'][disabled],
input[type='tel'][disabled],
input[type='text'][disabled],
input[type='submit'][disabled],
input[type='search'][disabled] {
    cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type='checkbox'][disabled] + label,
input[type='radio'][disabled] + label {
    cursor: not-allowed;
}

table {
    font-size: inherit;
    border-collapse: collapse;
    border-spacing: 0;
}

caption,
strong,
b {
    font-weight: font-weight.$bold;
}

ul ul {
    margin-bottom: 0;
}

form,
figure,
blockquote {
    margin: 0;
    padding: 0;
}

img,
iframe {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: none;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption,
turbo-frame {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: font-weight.$light;
    font-family: $headline-font;
}

a {
    color: inherit;
    text-decoration: none;
}

caption,
th,
td {
    // noinspection CssOverwrittenProperties
    text-align: left;

    // noinspection CssOverwrittenProperties
    text-align: start;
}

tr > :first-child {
    padding-left: 0;
}

th {
    font-weight: font-weight.$medium;
}

table td,
table th {
    padding: 4px 15px;
    vertical-align: top;
}

abbr,
acronym {
    font-variant: normal;
    border-bottom: 1px dotted colors.$text;
    cursor: help;
}

blockquote,
q {
    quotes: none;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

del {
    text-decoration: line-through;
}

ins {
    text-decoration: none;
}

pre,
code {
    font-family: monospace;
}

/* Vertical align */
.float_left {
    float: left;
}

.float_right {
    float: right;
}

/* Clear floats */
.clear,
#clear {
    clear: both;
    height: 0.1px;
    font-size: 0.1px;
    line-height: 0.1px;
}

/* Hide invisible elements */
.invisible,
.tns-visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
}

.handorgel .accordion {
    display: none;
    height: 0;
    overflow: hidden;
    transition: height 0.1s ease 0.1s;

    &.open {
        display: block;
        transition: height 0.2s ease;
    }

    &.active {
        overflow: visible;
    }
}

.nav-list,
.mod_navigation,
.mod_changelanguage,
.mod_breadcrumb {
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

.ce_download,
.ce_downloads {
    .size {
        display: none;
    }
}

@use '../globals/colors';
@use '../globals/font-weight';
@use '../globals/media';

.section-main {
    //min-height: calc(100vh - 199px);
    font-size: 18px;

    @include media.screen(media.$medium) {
        //min-height: auto;
    }

    h1 {
        @extend %h1;
    }

    h2 {
        @extend %h2;
    }

    h3 {
        @extend %h3;
    }

    strong {
        font-weight: font-weight.$black;
    }

    em {
        color: colors.$secondary;
        font-style: normal;
    }

    .subheadline {
        margin-top: -1em;
        color: colors.$secondary;
        font-size: 12px;
        line-height: 1.6;
        text-align: center;

        @include media.screen(media.$medium) {
            margin: -40px 0 30px;
        }
    }

    .mod_article > [class*='content-'],
    .mod_article > [class*='module-'] {
        margin-right: 30px;
        margin-left: 30px;
    }
}

.layout--home {
    #container {
        overflow: hidden;
    }

    .section-main {
        position: relative;
    }
}

.layout--content .section-main {
    max-width: $screen-large;
    margin: 45px auto 0;

    .mod_article {
        > .content-picto,
        > .content-grid,
        > .content-headline {
            max-width: none;
        }
    }

    @include media.screen(media.$medium, '.mod_article') {
        > * {
            max-width: calc(100% - 460px);
        }
    }

    @include media.screen(media.$medium, '.content-grid') {
        display: grid;
        grid-template-columns: 1fr 360px;
        gap: 50px;
        align-items: start;
    }

    @include media.screen(media.$large, '.content-grid') {
        gap: 40px;
    }
}

.illu--center .section-main,
.illu--contact .section-main {
    max-width: 750px;
    margin: 45px auto 0;

    @include media.screen(media.$medium, '.mod_article') {
        > * {
            max-width: none !important;
        }
    }
}

.layout--content {
    @include media.screen(media.$medium, '.section-main__inside') {
        padding-top: 50px;
        padding-bottom: 75px;
    }
}

@use '../globals/colors';
@use '../globals/font-weight';
@use '../globals/media';
@use '../globals/style';

.content-chatbot {
    margin: 0 -30px !important;
    padding: 20px 30px 35px;
    background: #fff;

    &__links {
        display: grid;
        grid-gap: 2vw;
        grid-template: 1fr / repeat(3, 1fr);
    }

    &__link {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;
        padding: 10px;
        color: #fff;
        font-weight: font-weight.$bold;
        font-size: 16px;
        font-family: $ci-font;
        text-align: center;
        border: 2px solid transparent;
        border-radius: 50%;

        $substances: (
            alcohol: colors.$alcohol,
            cannabis: colors.$cannabis,
            tabac: colors.$tabac,
        );

        @each $type, $color in $substances {
            &--#{$type} {
                background-color: $color;
            }

            &:hover {
                color: colors.$text;
                background: #fff;
                border-color: $color;
            }
        }

        svg {
            margin-bottom: 2%;
            width: 50%;
            height: 50%;
        }
    }
}

@include media.screen(media.$medium, '.content-chatbot') {
    margin: 0 !important;
    padding: 0;
}

.module-chatbot {
    margin-top: 2em;
    margin-bottom: 2em;

    &__show {
        position: relative;
        z-index: 10;
        max-width: 330px;
        margin-left: auto;
        margin-right: auto;
        @include style.linkbox(true, true);
    }

    &__popup {
        position: fixed;
        z-index: 2000;
        margin: 0 !important;
        padding: 0;
        transition: opacity 0.3s ease-in-out;
        inset: 0;
        opacity: 0;
        pointer-events: none;
    }

    &--popup .module-chatbot__popup {
        opacity: 1;
        pointer-events: auto;
    }

    &__inside {
        position: relative;
        height: 100vh;
        width: 100vw;
        margin: 0 auto;
        padding: 72px 0 0;
        background: #fff;
    }

    &__logo {
        position: absolute;
        inset: 22px auto auto 30px;
        width: 150px;
        height: 30px;
        background: url('../../images/logo.svg') 0 0 / contain no-repeat;
    }

    &__close {
        position: absolute;
        top: 25px;
        right: 25px;
        display: block;
        width: 25px;
        height: 25px;
        text-indent: -999em;
        background: transparent;
        border: none;
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            left: 0;
            width: 25px;
            height: 2px;
            background: colors.$secondary;
            transition: all 0.1s ease-in-out;
            content: '';
        }

        &::before {
            top: 10px;
            transform: rotateZ(45deg);
        }

        &::after {
            top: 10px;
            transform: rotateZ(-45deg);
        }
    }

    &__content {
        height: 100%;
    }

    iframe {
        width: 100%;
        min-height: 100% !important;
    }
}

@include media.screen(media.$medium, '.module-chatbot') {
    &__popup {
        display: grid;
        grid-template: 1fr / 1fr;
        align-items: center;
        justify-content: center;
        padding: 30px;
        background: rgb(255 255 255 / 1%);
        backdrop-filter: blur(5px);
    }

    &__inside {
        height: calc(100vh - 60px);
        width: 960px;
        max-width: 80vh;
        padding: 72px 30px 42px;
        border-radius: 20px;
    }
}

@use '../globals/colors';
@use '../globals/font-weight';
@use '../globals/media';

$this: '.section-header';

#{$this} {
    background: colors.$header;

    &__inside {
        display: grid;
        grid-template: 1fr / 1fr auto auto;
        align-items: center;
        max-width: $screen-large;
        height: 100%;
        margin: 0 auto;
    }

    &__logo {
        display: block;
        height: 30px;
        margin-left: 30px;
        background: url('../../images/logo.svg') 0 0 / contain no-repeat;
    }

    &__emergency {
        display: block;
        padding: 0 12px;
        color: #fff;
        font-weight: font-weight.$bold;
        font-size: 14px;
        font-family: $ci-font;
        line-height: 35px;
        background: colors.$secondary;
        border-radius: 50px;
    }
}

@include media.screen(media.$mobile-only, $this) {
    &__toggle {
        position: relative;
        width: 25px;
        height: 20px;
        margin: 0 29px 0 22px;
        background: none;
        border: none;
        cursor: pointer;

        span {
            display: block;
            width: 25px;
            height: 2px;
            background: colors.$secondary;
            transition: all 0.1s ease-in-out;

            &::before,
            &::after {
                position: absolute;
                left: 0;
                width: 25px;
                height: 2px;
                background: colors.$secondary;
                transition: all 0.1s ease-in-out;
                content: '';
            }

            &::before {
                top: 0;
            }

            &::after {
                bottom: 0;
            }
        }

        body.has-overlay & {
            span {
                background: transparent;

                &::before {
                    top: 9px;
                    width: 25px;
                    transform: rotateZ(45deg);
                }

                &::after {
                    bottom: 9px;
                    width: 25px;
                    transform: rotateZ(-45deg);
                }
            }
        }
    }

    &__overlay {
        position: fixed;
        inset: 80px 0 0;
        z-index: 999;
        display: grid;
        grid-template: 1fr 100px 100px / auto;
        padding: 27px;
        overflow-y: auto;
        background: #fff;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        pointer-events: none;

        body.has-overlay & {
            opacity: 1;
            pointer-events: auto;
        }

        &-illu {
            position: absolute;
            z-index: -1;

            &--serpenti {
                inset: -4px auto auto -67px;
            }

            &--bubble {
                inset: 51vh calc(50vw - 160px) auto auto;
            }

            &--moon {
                inset: auto 26px -52px auto;
            }

            &--line {
                inset: auto 160px 80px auto;
                width: 405px;

                img {
                }
            }
        }
    }

    &__navigation {
        align-self: center;
        line-height: 1.1;

        &__group--level1 {
            max-width: 340px;
            margin: 0 auto;
        }

        &__items--level1 {
            display: grid;
            grid-auto-flow: row;
            gap: 10px;
        }

        &__items--level2 {
            display: grid;
            grid-auto-flow: row;
            gap: 9px;
            padding-top: 11px !important;
        }

        &__link {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-weight: font-weight.$bold;
            font-size: 22px;
            font-family: $ci-font;
            line-height: 1;
            border: 2px solid colors.$secondary;
            border-radius: 50px;
            cursor: pointer;

            &--level1 {
                padding: 27px 0 26px;
                color: colors.$primary;
                background: #fff;
            }

            &--level2 {
                padding: 9px 0 10px;
                color: colors.$secondary;
            }
        }

        &__back {
            @include link-back;

            display: block;
            margin-top: 25px;
            margin-left: calc(50% - 25px);
        }
    }

    &__social {
        ul {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        a {
            display: block;
            width: 35px;
            height: 35px;
            text-indent: -999em;
            background: #fff;

            &.instagram {
                background: url('../../images/social__instagram.svg') 0 0 / contain no-repeat;
            }

            &.youtube {
                background: url('../../images/social__youtube.svg') 0 0 / contain no-repeat;
            }

            &.tiktok {
                background: url('../../images/social__tiktok.svg') 0 0 / contain no-repeat;
            }
        }
    }

    &__meta {
        display: flex;
        align-items: center;
        justify-content: center;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        li.alerts--desktop {
            display: none;
        }

        a {
            color: colors.$footer;
            text-decoration: none;

            &.alerts {
                color: colors.$secondary;
                text-decoration: underline;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@include media.screen(media.$medium, $this) {
    &__inside {
        grid-template: 1fr / 1fr 3fr auto;
        margin-top: 15px;
    }

    &__logo {
        order: 1;
        height: 40px;
    }

    &__toggle {
        display: none;
    }

    &__emergency {
        order: 3;
        font-size: 14px;
    }

    &__overlay {
        order: 2;

        &-illu {
            display: none;
        }
    }

    &__navigation {
        &__back {
            display: none;
        }

        &__items {
            display: grid;
            grid-auto-flow: column;
        }

        a,
        button {
            color: colors.$primary;
            font-weight: font-weight.$bold;
            font-size: 14px;
            font-family: $ci-font;
            background: none;
            border: none;
            cursor: pointer;
        }

        &__group--level2 {
            display: none;
            position: absolute;
            min-width: 100%;
            margin-left: -28px;
            padding: 0;
            background: #fff;

            > :first-child {
                padding: 35px 28px 18px;
            }
        }

        &__items--level2 {
            grid-auto-flow: row;

            a {
                display: block;
                padding: 10px 28px 10px 0;
                color: colors.$secondary;
                border-top: 1px solid colors.$secondary;
                white-space: nowrap;
            }
        }

        &__item--level1.submenu {
            position: relative;

            //&:hover {
            //    #{$this}__navigation__group--level2 {
            //        display: block;
            //    }
            //}
        }
    }

    &__meta {
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 18px;
        font-family: $ci-font;

        ul {
            display: flex;
            gap: 15px;
        }

        li.alerts--mobile {
            display: none;
        }

        a {
            color: colors.$footer;

            &.alerts {
                color: colors.$primary;
                font-weight: font-weight.$bold;
                text-transform: uppercase;
                text-decoration: underline;
            }
        }
    }

    &__social {
        display: none;
    }
}

@use '../globals/colors';
@use '../globals/font-weight';
@use '../globals/media';

$this: '.content-rsce-emergency-numbers';

#{$this} {
    margin-bottom: 3em;
    color: colors.$secondary;

    &__headline {
        margin-bottom: 2em !important;
    }

    &__link {
        display: block !important;
        text-align: center;
    }

    &__description {
        font-weight: font-weight.$regular;
    }

    &__text {
        font-weight: font-weight.$bold;
        text-align: center;
    }

    .crisis-situation + & {
        #{$this}__headline {
            text-align: center;
        }
    }
}

@include media.screen(media.$medium, $this) {
    margin-bottom: 0;
    padding: 30px;
    color: #fff;
    background: colors.$secondary;

    &__headline {
        margin-top: 0 !important;
        color: #fff !important;
        font-size: 25px !important;
    }

    &__link {
        border-color: #fff !important;
    }
}

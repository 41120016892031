@use '../defaults';
@use '../globals/colors';
@use '../globals/font-weight';
@use '../globals/media';
@use '../globals/style';

:root {
    //--sjs-font-family: defaults.$body-font;
    --sjs-font-questiondescription-weight: font-weight.$light;
    --sjs-font-editorfont-weight: font-weight.$light;
    //--sjs-font-questiontitle-weight: font-weight.$semibold;
}

.sd-root-modern {
    overflow: visible !important;
    background-color: transparent !important;

    .sv-components-container-center {
        position: relative;
    }

    .sd-progress-buttons--top {
        margin-top: -32px;
        margin-right: 40px;
    }

    .sd-btn {
        @include style.linkbox($block: true, $important: true);
    }

    .sd-navigation__start-btn,
    .sd-navigation__next-btn {
        @include style.linkbox($primary: true, $block: true, $only: true, $important: true);
    }
}

.module-survey {
    &__request {
        position: fixed;
        z-index: 100;
        right: 20px;
        bottom: -100%;
        margin-left: 20px;
        padding: 20px;
        background: #fff;
        box-shadow: rgba(255, 255, 255, 0.5) 0px 7px 29px 0px;
        border-radius: 20px;
        transition: bottom .5s linear(0, 0.402 7.4%, 0.711 15.3%, 0.929 23.7%, 1.008 28.2%, 1.067 33%, 1.099 36.9%, 1.12 41%, 1.13 45.4%, 1.13 50.1%, 1.111 58.5%, 1.019 83.2%, 1.004 91.3%, 1);

        p {
            margin: 0 0 1em;
        }
    }

    &--ready {
        .module-survey__request {
            bottom: 20px;
        }
    }

    &__action {
        @include style.linkbox();
        margin-right: 10px;

        &--start {
            @include style.linkbox(true);
            width: auto;
        }
    }

    &__modal {
        position: fixed;
        inset: 0;
        overflow: auto;
        z-index: 2000;
        display: grid;
        grid-template: 1fr / 1fr;
        align-items: center;
        justify-items: center;
        margin: 0 !important;
        padding: 30px;
        font-size: 16px;
        background: rgb(255 255 255 / 1%);
        backdrop-filter: blur(5px);
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
        pointer-events: none;
    }

    &--active {
        .module-survey__modal {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__inside {
        position: relative;
        display: grid;
        grid-template: 1fr auto/1fr;
        width: 460px;
        max-width: 80vw;
        padding: 42px 10px 10px;
        background: #fff;
        border-radius: 20px;
    }

    &__close {
        position: absolute;
        z-index: 10;
        top: 25px;
        right: 25px;
        display: block;
        width: 25px;
        height: 25px;
        text-indent: -999em;
        background: transparent;
        border: none;
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            left: 0;
            width: 25px;
            height: 2px;
            background: colors.$secondary;
            transition: all 0.1s ease-in-out;
            content: '';
        }

        &::before {
            top: 10px;
            transform: rotateZ(45deg);
        }

        &::after {
            top: 10px;
            transform: rotateZ(-45deg);
        }
    }
}

@include media.screen(media.$medium, '.module-survey') {
    &__modal {
        padding: 30px;
    }

    &__inside {
        padding: 42px 30px;
        border-radius: 20px;
    }
}

@use '../globals/colors';
@use '../globals/font-weight';
@use '../globals/media';

$this: '.content-popup';

#{$this} {
    &__text {
        a {
            text-decoration: underline;
        }
    }
}

@include media.screen(media.$mobile-only, $this) {
    position: fixed;
    z-index: 2000;
    display: grid;
    grid-template: 1fr / 1fr;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 30px;
    font-size: 16px;
    background: rgb(255 255 255 / 1%);
    opacity: 1;
    backdrop-filter: blur(5px);
    transition: opacity 0.3s ease-in-out;
    inset: 0;

    &--hidden {
        opacity: 0;
        pointer-events: none;
    }

    &__inside {
        position: relative;
        display: grid;
        grid-template: 1fr auto/1fr;
        max-height: calc(100vh - 60px);
        padding: 72px 30px 42px;
        text-align: center;
        background: #fff;
        border-radius: 20px;
    }

    &__close {
        position: absolute;
        top: 25px;
        right: 25px;
        display: block;
        width: 25px;
        height: 25px;
        text-indent: -999em;
        background: transparent;
        border: none;
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            left: 0;
            width: 25px;
            height: 2px;
            background: colors.$secondary;
            transition: all 0.1s ease-in-out;
            content: '';
        }

        &::before {
            top: 10px;
            transform: rotateZ(45deg);
        }

        &::after {
            top: 10px;
            transform: rotateZ(-45deg);
        }
    }

    &__headline {
        margin-top: 0 !important;
    }

    &__text {
        overflow-y: auto;
    }

    &__button {
        width: 100%;
        margin-top: 31px;
        padding: 16px 12px 15px;
        color: #fff;
        font-weight: font-weight.$bold;
        line-height: 1.25;
        background: colors.$secondary;
        border: none;
        border-radius: 50px;
        cursor: pointer;
    }

    &--intro {
        #{$this} {
            &__headline {
                margin: 0 0 22px !important;
                font-weight: font-weight.$bold;
                font-size: 22px;
                color: colors.$text;
            }

            &__logo {
                width: 220px;
                margin: 12px auto 0;
                text-align: center;
            }
        }
    }
}

@include media.screen(media.$medium, $this) {
    &__close,
    &__button {
        display: none;
    }

    &--intro {
        margin-top: 50px;
        text-align: center;

        #{$this} {
            &__inside {
                max-width: 750px;
                margin: 0 auto;
            }

            &__headline {
                margin: 0 0 1em !important;
                font-weight: font-weight.$bold;
                font-size: 22px;
            }

            &__logo {
                display: inline;
                margin-left: 5px;

                img {
                    height: 1.5em;
                }
            }

            &__text {
                font-size: 14px;
                line-height: 1.75;
            }
        }
    }

    &:not(#{$this}--intro) {
        padding: 30px;
        color: #fff;
        background: colors.$secondary;

        h2 {
            margin-top: 0;
            color: #fff;
        }

        #{$this}__link {
            border-color: #fff !important;
        }
    }
}

@use 'sass:map';
@use '../globals/colors';
@use '../globals/font-weight';
@use '../globals/media';
@use '../globals/style';

$this: '.module-counseling';

$substances: (
    alcohol: (
        background: colors.$alcohol,
        text: colors.$alcohol-text,
    ),
    cannabis: (
        background: colors.$cannabis,
        text: colors.$cannabis-text,
    ),
    tabac: (
        background: colors.$tabac,
        text: colors.$tabac-text,
    ),
    medicaments: (
        background: colors.$medicaments,
        text: colors.$medicaments-text,
    ),
);

#{$this} {
    &__root,
    &__filter {
        position: relative;
    }

    &__navigation {
        position: relative;
        width: 100vw;
        min-height: var(--container);
        overflow: hidden;
    }

    &__container {
        position: absolute;
        top: 0;
        left: 100vw;
        display: grid;
        width: 100%;
        min-height: var(--container);
        padding: 0 30px;

        ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;
            justify-content: center;
            max-width: 304px;
            margin: 0 auto;
            padding: 30px 0;
        }

        li {
            display: flex;
        }

        a,
        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 20px 35px;
            color: #fff;
            font-weight: font-weight.$bold;
            font-size: 25px;
            font-family: $ci-font;
            line-height: 1.25;
            text-align: center;
            background: colors.$primary;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.1s ease-in-out;

            &:hover {
                background-color: colors.$secondary;
            }

            span + span {
                display: block;
                margin-top: 0.5em;
                font-size: 0.7em;
            }
        }
    }

    &__container--level1 {
        ul {
            max-width: 250px;
        }

        li {
            flex-grow: 1;
            max-height: 150px;
            width: 100%;
        }
    }

    &__container--level2,
    &__container--level3 {
        align-items: center;

        ul {
            display: grid;
            max-width: 380px;
        }

        a,
        button {
            font-size: 16px;

            svg {
                width: 50%;
            }
        }
    }

    &__container--level2 {
        ul {
            grid-template: auto auto auto / 1fr 1fr;
        }

        li {
            max-height: none;
            grid-row: 3 / span 1;
            grid-column: 1 / span 2;
            aspect-ratio: 1 / 0.25;

            @each $type, $color in $substances {
                &.#{$type} {
                    grid-row: unset;
                    grid-column: unset;
                    aspect-ratio: 1 / 1;
                }
            }
        }

        a,
        button {
            padding: 0;

            @each $type, $color in $substances {
                &.#{$type} {
                    gap: 10%;
                    justify-content: end;
                    padding-bottom: 15%;
                    background-color: map.get($color, background);
                    transition: background-color 0.1s ease-in-out;

                    svg {
                        color: map.get($color, text);
                        transition: color 0.1s ease-in-out;
                    }

                    &:hover {
                        background-color: colors.$secondary;

                        svg {
                            color: colors.$primary;
                        }
                    }
                }
            }
        }
    }

    &__container--level3 {
        ul {
            grid-auto-flow: row;
        }

        a,
        button {
            padding: 20px;
        }
    }

    &__filter {
        display: flex;
        flex-direction: column;
        max-width: 480px;
        margin: 0 auto;
        padding: 0 30px;
        min-height: var(--container);

        &-illu {
            position: absolute;
            z-index: 10;


        }

        &-headline {
            position: relative;
            padding-left: 40px;
        }

        &-action {
            display: flex;
            justify-content: center;
            margin: 40px 0 140px;

            button {
                @include style.linkbox(true, true);

                & {
                    max-width: 330px;
                }
            }
        }

        &-back {
            &--mobile {
                @include link-back(25px);
                position: absolute;
                left: 0;
            }

            &--desktop {
                display: none;
            }
        }

        fieldset {
            display: grid;
            grid-auto-flow: row;
        }
    }
}

@include media.screen(media.$medium, $this) {
    &__root {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: var(--container);

        > .content-popup {
            display: flex;
            flex-grow: 1;
            align-items: flex-end;
        }
    }

    &__navigation {
        flex-grow: 5;
        width: 1200px;
        max-width: 100vw;
        margin: 0 auto;
        min-height: 500px;
        height: 100%;
        z-index: 10;
    }

    &__container {
        top: 100vh;
        left: auto;
        min-height: 0;
        height: 100%;

        ul {
            gap: 30px;
        }
    }

    &__container--level1 {
        ul {
            flex-direction: row;
            width: 100%;
            max-width: none;
        }

        li {
            width: 33.3333%;
            max-height: none;
            aspect-ratio: 1 / 1;
        }

        a,
        button {
            padding: 0 35px;
            font-size: 30px;
        }
    }

    &__container--level2 {
        ul {
            grid-template: auto auto / repeat(4, 1fr);
            max-width: none;
        }

        li {
            grid-row: 2 / span 1;
            grid-column: 1 / span 4;
            height: 71px;
            aspect-ratio: inherit;

            @each $type, $color in $substances {
                &.#{$type} {
                    grid-row: unset;
                    grid-column: unset;
                    height: unset;
                    aspect-ratio: 1;
                }
            }
        }
    }

    &__container--level3 {
        ul {
            grid-template: repeat(2, 1fr) / repeat(3, 1fr);
            max-width: none;
        }

        li {
        //    grid-row: 2 / span 1;
        //    grid-column: 1 / span 4;
        //    height: 71px;
            aspect-ratio: 2/1;
        }

        a,
        button {
            font-size: 20px;
        }
    }

    &__filter {
        max-width: $screen-large;
        justify-content: center;

        &-headline,
        .subheadline {
            text-align: left !important;
            padding-left: 0;
        }

        &-action {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 90px;
        }

        &-back {
            &--mobile {
                display: none;
            }

            &--desktop {
                display: block;
                font-size: 12px;
                color: colors.$secondary;
            }
        }
    }
}

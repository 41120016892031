$this: '.content-accordion';

#{$this} {
    position: relative;
    width: auto !important;
    margin: 130px 0 2em !important;
    border: none !important;

    .content-grid & {
        margin-left: -30px !important;
        margin-right: -30px !important;
    }

    .handorgel {
        &__header,
        &__content {
            background: #fff !important;
            border: none;
        }

        &__header {
            margin-top: 6px;
            margin-bottom: 0;

            &__button {
                position: relative;
                padding: 15px 55px 15px 15px;
                font-size: 25px;
                line-height: 1;
                background: transparent !important;

                &::after {
                    position: absolute;
                    width: 40px;
                    height: 20px;
                    background: url('../../images/accordion.svg') center center/contain no-repeat;
                    transition: transform 0.2s ease-in-out;
                    content: '';
                    pointer-events: none;
                    inset: auto 15px 15px auto;
                }
            }

            &--open {
                .handorgel__header__button::after {
                    transform: rotateZ(180deg);
                }
            }
        }

        &__content {
            &__inner {
                padding: 0 15px;
                overflow: hidden;
            }
        }
    }
}

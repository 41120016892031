@use '../globals/colors';
@use '../globals/media';

$this: '.content-text';

#{$this} {
    h2,
    p {
        transition: all 0.4s ease-in-out;
    }

    &--blur {
        h2,
        p {
            color: transparent;
            text-shadow: 0 0 32px colors.$text;
        }
    }

    p {
        text-align: justify;
        hyphens: auto;
    }

    a {
        text-decoration: underline;
    }

    :not(.handorgel__content__inner) > & {
        ol {
            margin: 0;
            padding: 0;
            list-style: none;
            counter-reset: numlist;

            li {
                position: relative;
                margin: 1em 0;
                padding-left: 48px;
                counter-increment: numlist;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 30px;
                    height: 30px;
                    color: colors.$secondary;
                    font-size: 16px;
                    font-family: $ci-font;
                    line-height: 30px;
                    text-align: center;
                    background: colors.$primary;
                    border-radius: 50%;
                    content: counter(numlist);
                }
            }
        }
    }
}

@include media.screen(media.$medium, $this) {
    :not(.handorgel__content__inner) > & {
        ol {
            li {
                margin: 1.5em 0;
                padding-left: 70px;
            }
        }
    }
}

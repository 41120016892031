@use '../globals/media';

$this: '.illu';

@keyframes illu-rotation {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

#{$this} {
    &__container {
        position: absolute;
        width: 100%;
        inset: 0 auto;
        overflow: hidden;
        pointer-events: none;

        .module-counseling__root & {
            top: -90px;
        }
    }

    &__item {
        position: absolute;
        overflow: hidden;
        z-index: -1;
        pointer-events: none;

        img {
            width: 100%;
            height: auto;
            max-width: none;
        }
    }
}

@include media.screen(media.$mobile-only, $this) {
    &--home {
        #{$this}__item {
            // Animated Items

            &--serpenti {
                overflow: visible;
            }

            &--explosion {
                overflow: visible;
            }

            &--moon {
                overflow: visible;

                img {
                    transform: rotateY(180deg);
                }
            }

            &--galaxy {
                overflow: visible;
            }

            // Navigation Page

            &--star8pink {
                position: fixed;
                top: 70px;
                right: 20px;
                width: 88px;
                animation: 10s linear infinite illu-rotation;
                z-index: 101;
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
            }

            &--circle {
                bottom: 43px;
                left: 10px;
                width: 96px;
            }

            &--potato {
                right: -53px;
                bottom: 27px;
                width: 136px;
                z-index: 1;
            }

            // Filter Page

            &--donut {
                display: none;
            }

            &--wiggle {
                bottom: 22px;
                left: -36px;
                width: 267px;
            }

            &--round {
                right: -70px;
                bottom: -147px;
                width: 200px;
            }

            &--star6 {
                bottom: 75px;
                left: 14px;
                width: 89px;
                z-index: 1;
                opacity: 0;
            }
        }

        &.has-overlay #{$this}__item--star8pink {
            opacity: 0;
        }
    }

    &--substance {
        .section-main {
            margin-bottom: 60px;
        }

        #{$this}__item {
            &--eye,
            &--round,
            &--star5 {
                display: none;
            }

            &--cloud {
                width: 240px;
                top: -60px;
                left: calc(50% - 120px);
            }

            &--cross {
                width: 171px;
                right: 0;
                bottom: -201px;

                img {
                    width: 201px;
                }
            }

            &--line {
                width: 406px;
                right: 67px;
                bottom: 96px;
                z-index: 1;
            }
        }
    }

    &--aide {
        .section-main {
            margin-bottom: 60px;
        }

        #{$this}__item {
            &--vu {
                display: none;
            }

            &--potato1 {
                top: 15%;
                right: -82px;
                width: 175px;
            }

            &--potato2 {
                top: calc(15% + 100px);
                right: -130px;
                width: 175px;
                transform: rotate(159.647deg);
            }

            &--explosion {
                right: -180px;
                top: 50%;
                width: 386px;
                height: 300px;
                transform: rotate(121.378deg);
            }

            &--questionmark {
                right: -39px;
                bottom: 75px;
                width: 135px;
                z-index: 1;
            }

            &--star4pink {
                right: 110px;
                bottom: 95px;
                width: 59px;
                z-index: 1;
            }
        }
    }

    &--urgence {
        .section-main {
            margin-bottom: 120px;
        }

        #{$this}__item {
            &--hand,
            &--serpenti,
            &--star8pink {
                display: none;
            }

            &--moon {
                right: -7px;
                bottom: 66px;
                width: 157px;
                transform: rotate(90deg);
                z-index: 1;
            }
        }

        .content-rsce-emergency-numbers {
            position: relative;

            #{$this}__item--hand {
                display: block;
                bottom: -40px;
                left: -89px;
                width: 251.126px;
                transform: rotate(-34.076deg);
            }
        }
    }

    &--center,
    &--contact {
        .section-main {
            margin-bottom: 185px;
        }

        #{$this}__item {
            &--star4,
            &--potato1,
            &--potato2 {
                display: none;
            }

            &--galaxy {
                right: -80px;
                bottom: 520px;
                width: 153px;
                transform: rotate(-76.792deg);
            }

            &--donut {
                bottom: 350px;
                left: -71px;
                width: 152px;
            }

            &--blocsquare {
                width: 374px;
                right: -220px;
                bottom: -80px;
                z-index: 1;
            }

            &--line {
                bottom: 199px;
                left: -96px;
                width: 292px;
            }
        }
    }

    &--center {
        #{$this}__item {
            &--donut,
            &--galaxy {
                display: none;
            }
        }
    }
}

@include media.screen(media.$medium, $this) {
    &__item {
        display: block !important;
    }

    &--home {
        #{$this}__item {
            &--round {
                display: none;
            }

            // Animated Items

            &--explosion {
                top: 132px;
                right: calc((100vw - 1200px) / 2 - 220px);
                width: 440px;
            }

            &--serpenti {
                top: 270px;
                left: 25px;
                width: 217px;
            }

            &--moon {
                bottom: 33px;
                left: calc((100vw - 1200px) / 2 + 294px);
                width: 258px;

                img {
                    transform: rotateY(180deg);
                }
            }

            &--galaxy {
                top: 339px;
                left: calc((100vw - 1200px) / 2 + 217px);
                width: 241px;
            }

            // Navigation Page

            &--star8pink {
                bottom: 115px;
                left: calc((100vw - 1200px) / 2 + 13px);
                width: 132px;
                animation: 10s linear infinite illu-rotation;
            }

            &--circle {
                top: 204px;
                left: -24px;
                width: 146px;
            }

            &--potato {
                bottom: 133px;
                left: calc((100vw - 1200px) / 2 + 850px);
                width: 224px;
                z-index: 2;
            }

            // Filter Page

            &--wiggle {
                left: calc((100vw - 1200px) / 2 + 800px);
                bottom: 300px;
                width: 510px;
            }

            &--star6 {
                bottom: 175px;
                right: 32px;
                width: 106px;
                opacity: 0;
            }

            &--donut {
                bottom: -58px;
                left: calc((100vw - 1200px) / 2 + 1000px);
                width: 247px;
                z-index: 1;
            }
        }
    }

    &--substance {
        .section-main {
            margin-bottom: 100px;
        }

        #{$this}__item {
            &--cloud {
                width: 286px;
                top: 0;
                right: -220px;
            }

            &--star5 {
                top: -150px;
                right: -500px;
                width: 188px;
            }

            &--cross {
                right: -410px;
                bottom: -201px;
                width: 298px;
                z-index: 1;

                img {
                    width: 424px;
                }
            }

            &--line {
                width: 738px;
                right: 67px;
                bottom: 70px;
                z-index: 1;
            }

            &--eye {
                left: -270px;
                bottom: -270px;
                width: 396px;
                transform: rotate(41.809deg);
            }

            &--round {
                top: 200px;
                right: calc(50vw - 600px);
                width: 219px;
            }
        }
    }

    &--aide {
        .section-main {
            margin-bottom: 60px;
        }

        #{$this}__item {
            &--vu {
                top: 350px;
                left: calc((100vw - 1200px) / 2 - 120px);
                width: 426.2px;
                transform: rotate(35.346deg);
            }

            &--potato1 {
                top: 609px;
                right: -64px;
                width: 233px;
            }

            &--potato2 {
                top: 740px;
                right: -120px;
                width: 233.296px;
                transform: rotate(159.647deg);
            }

            &--explosion {
                top: 650px;
                left: calc((100vw - 1200px) / 2 - 320px);
                width: 657.475px;
                transform: rotate(125.454deg);
            }

            &--questionmark {
                right: -86px;
                bottom: 66px;
                width: 223px;
                z-index: 1;
            }

            &--star4pink {
                right: 191px;
                bottom: 158px;
                width: 103px;
            }
        }
    }

    &--urgence {
        #{$this}__item {
            &--moon {
                top: 280px;
                left: calc((100vw - 1200px) / 2 + 720px);
                width: 284px;
                transform: rotateZ(188deg)
            }

            &--serpenti {
                top: 50%;
                right: 40px;
                width: 175px;
                transform: rotate(-111.285deg);
            }

            &--hand {
                bottom: 100px;
                right: -90px;
                width: 536.817px;
                transform: rotateY(180deg) rotateZ(-25deg);
                z-index: 1;
            }

            &--star8pink {
                left: calc((100vw - 1200px) / 2 + 692px);
                bottom: 24px;
                width: 152px;
                z-index: 1;
            }
        }

        .content-rsce-emergency-numbers {
            #{$this}__item--hand {
                display: none !important;
            }
        }
    }

    &--center,
    &--contact {
        .section-main {
            max-width: 750px;
            margin: 45px auto 60px;
        }

        #{$this}__item {
            &--galaxy {
                display: none !important;
            }

            &--star4 {
                top: 306px;
                right: -49px;
                width: 158px;
            }

            &--potato1 {
                top: 100px;
                left: 40px;
                width: 140px;
                transform: rotate(80deg);
            }

            &--potato2 {
                top: 150px;
                left: -40px;
                width: 156.244px;
                transform: rotate(58.035deg);
            }

            &--donut {
                width: 435px;
                bottom: 18px;
                left: -300px;
            }

            &--blocsquare {
                width: 504px;
                right: -200px;
                bottom: -82px;
                z-index: 1;
            }

            &--line {
                width: 292px;
                left: calc((100vw - 1200px) / 2 + 135px);
                bottom: 182px;
            }
        }
    }

    &--center {
        .section-main {
            max-width: 750px;
            margin: 45px auto 300px;
        }
    }
}

@include media.screen(1200, $this) {
    &__page {
        max-width: 1200px;
        left: calc(50vw - 600px);
    }

    &--substance {
        #{$this}__item {
            &--cross {
                right: auto;
                left: calc(50vw + 298px);
            }
        }
    }
}

@include media.screen(1480, $this) {
    &--center,
    &--contact {
        #{$this}__item {
            &--star4 {
                right: -29px;
            }

            &--donut {
                left: -250px;
            }

            &--blocsquare {
                right: -125px;
                bottom: -32px;
            }
        }
    }
}

$text: #000;
$primary: #642323;
$secondary: #e85a5e;
$accent: #ffb78e;
$background: #f2f0ec;
$alcohol: #a12700;
$alcohol-text: #e85a5e;
$cannabis: #5b6423;
$cannabis-text: #ffb78e;
$tabac: #cf6745;
$tabac-text: #ffb78e;
$medicaments: #5e3e55;
$medicaments-text: #e85a5e;
$header: #fff;
$footer: #d6d1c7;

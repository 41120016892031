@use '../globals/colors';
@use '../globals/media';

.content-text.crisis-situation {
    @include media.screen(media.$mobile-only) {
        margin: 0 -30px;
        padding: 30px;
        color: #fff;
        background: colors.$secondary;

        h2 {
            margin-top: 1em;
            color: inherit;
            text-align: center;
        }

        ol li::before {
            background: #fff;
        }
    }

    @include media.screen(media.$medium) {
        max-width: 650px;
    }
}

.content-text.safety-position {
    @include media.screen(media.$mobile-only) {
        margin: 0 !important;
        padding: 30px;
        background: #fff;

        h2 {
            max-width: 280px;
            margin: 0 0 2em 48px;
        }

        p {
            margin: 2em 0;
            text-align: center;
        }

        ol li::before {
            color: #fff;
            background: colors.$secondary;
        }
    }

    @include media.screen(media.$medium) {
        p {
            margin-left: 70px;
        }
    }
}

.contact {
    margin: 60px 0;

    p {
        text-align: center;
    }

    @include screen(media.$medium) {
        margin: 120px 0 0;
    }
}

@include media.screen(media.$medium) {
    .content-element-group.aide {
        padding: 0 100px 30px;
        font-size: 16px;
        background: #fff;

        .content-grid + & {
            margin-top: -4em;
        }

        &,
        + .content-element-group {
            h2, h3 {
                font-size: 18px;
                color: colors.$text;
            }

            h2 {
                margin-top: 4em;
                margin-bottom: 2em;
            }
        }

        + .content-element-group {
            h2:first-child, h3:first-child {
                margin-top: 0;
            }
        }
    }

    .content-grid > :first-child {
        > h1:first-child,
        > h2:first-child,
        > h3:first-child {
            margin-top: 0;
        }
    }
}
